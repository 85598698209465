import React, {useState} from "react";
import styles from "./Contact.module.css";

import useDataPost from "../../hooks/useDataPost";
import {Button, Stack} from "@mui/material";
import {useSectionsRefs} from "../mainWrapper/MainWrapper";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CustomTextField from "../customMUIComponents/CustomTextField";

const Contact = () => {

    const {contactSection} = useSectionsRefs();

    const [firstname, setFirstname] = useState<string>('');
    const [lastname, setLastname] = useState<string>('');
    const [society, setSociety] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [phoneNumber, setPhoneNumber] = useState<string>('');
    const [message, setMessage] = useState<string>('');

    const {postDataPromise} = useDataPost('', {});
    const [disableSendButton, setDisableSendButton] = useState<boolean>(false);

    const submit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setDisableSendButton(true);
        postDataPromise('/contact', {
            firstname,
            lastname,
            society,
            email,
            phoneNumber,
            message
        }).then((response) => {
            window.location.reload();
        }).catch((error) => {
            setDisableSendButton(false);
        });
    }

    return (
        <Box
            ref={contactSection}
            sx={{
                backgroundColor: "primary.main",
                display: "flex",
                justifyContent: "center",
            }}
        >
            <div className={styles.content}>
                <Typography
                    sx={{
                        color: '#ffffff',
                        fontSize: {xs: 30, sm: 40},
                    }}
                    variant={"firstVariant"}
                >
                    CONTACT
                </Typography>

                <Typography
                    sx={{
                        color: '#ffffff',
                        fontSize: {xs: 18, sm: 20, md: 24},
                        mb: 4,
                    }}
                    variant={"firstVariant"}
                >
                    Une question ? Vous souhaitez une démo du produit ? Que vous soyez une société ou un client de la
                    plateforme, n’hésitez pas à nous contacter et nous reviendrons vers vous dans les 48 heures.
                </Typography>

                <form onSubmit={(e) => submit(e)}>
                    <Stack spacing={2} direction="row" sx={{marginBottom: 4}}>
                        <CustomTextField
                            value={firstname}
                            label="Prénom"
                            setField={setFirstname}
                            required/>
                        <CustomTextField
                            value={lastname}
                            label="Nom"
                            setField={setLastname}
                            required/>
                    </Stack>

                    <CustomTextField
                        value={society}
                        label="Société" setField={setSociety}/>

                    <Stack spacing={2} direction="row" sx={{marginTop: 4}}>
                        <CustomTextField
                            value={email}
                            label="Email"
                            type="email"
                            setField={setEmail}
                            required/>
                        <CustomTextField
                            value={phoneNumber}
                            label="N° téléphone"
                            type="text"
                            setField={setPhoneNumber}
                            required/>
                    </Stack>

                    <CustomTextField
                        value={message}
                        label="Votre message"
                        setField={setMessage}
                        required
                        multiline
                        rows={6}
                        mt={4}
                        mb={4}/>

                    <Button
                        variant="firstVariant"
                        type="submit"
                        fullWidth
                        disabled={disableSendButton}
                    >
                        Envoyer
                    </Button>
                </form>
            </div>
        </Box>
    );
}

export default Contact