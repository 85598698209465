import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {useCart} from "../../hooks/useCart";
import Drawer from "@mui/material/Drawer";
import {CartItem} from "../../context/ShoppingCartContext";
import {Grid2} from "@mui/material";
import CartOverlayItem from "./cartOverlayItem/CartOverlayItem";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import {useNavigate} from "react-router-dom";
import useFormatNumber from "../../hooks/useFormatNumber";

interface CartOverlayProps {
    isCartOpen: boolean;
    closeCart: () => void;
}

const CartOverlay = ({isCartOpen, closeCart}: CartOverlayProps) => {

    const {cartItems, getItemsNumber, getTotalPrice} = useCart();
    const navigate = useNavigate();
    const {formatCentsToPrice} = useFormatNumber();

    return (
        <Drawer anchor="right" open={isCartOpen} onClose={closeCart}>
            <Box
                sx={{
                    width: {xs: '275px', sm: '350px', md: '425px'},
                    p: 2,
                }}
            >
                <Typography
                    variant={"secondVariant"}
                    textAlign={"center"}
                    sx={{
                        fontSize: {xs: 25, sm: 30},
                    }}
                >
                    Votre panier
                </Typography>
                <Divider sx={{mb: 4, mt: 2}}/>
                {getItemsNumber() > 0 ? (
                    <Grid2 container>
                        {cartItems.map((cartItem: CartItem, index) => (
                            <Grid2 key={cartItem.product.id}>
                                <CartOverlayItem product={cartItem.product} quantity={cartItem.quantity}/>
                                <Divider sx={{mb: 4, mt: 4}}/>
                            </Grid2>
                        ))}
                        <Grid2 container sx={{width: '100%'}}>
                            <Grid2 size={6} sx={{alignContent: 'center'}}>
                                <Typography
                                    variant={"secondVariant"}
                                    textAlign={"left"}
                                    sx={{
                                        fontSize: {xs: 18, sm: 20},
                                    }}
                                >
                                    Total: {formatCentsToPrice(getTotalPrice())}.-
                                </Typography>
                            </Grid2>
                            <Grid2 size={6} sx={{textAlign: 'right'}}>
                                <Button
                                    variant={"firstVariant"}
                                    onClick={() => navigate('/cart')}
                                >Commander
                                </Button>
                            </Grid2>
                        </Grid2>
                    </Grid2>
                ) : (
                    <Typography
                        variant={"secondVariant"}
                        textAlign={"center"}
                        sx={{
                            fontSize: {xs: 18, sm: 20},
                        }}
                    >
                        Votre panier est vide.
                    </Typography>
                )}

            </Box>
        </Drawer>
    );
}

export default CartOverlay