import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import {StepDescription} from "../../pages/register/Register";
import Typography from "@mui/material/Typography";

interface RegisterStepperProps {
    activeStep: number,
    stepsDescriptions: StepDescription[]
}

const CheckoutStepper = ({activeStep, stepsDescriptions}: RegisterStepperProps) => {

    return (
        <Box
            sx={{
                width: '100%',
                display: {xs: 'none', sm: 'block'},
                mb: 4,
            }}>
            <Stepper activeStep={activeStep} alternativeLabel>
                {stepsDescriptions.map((stepDescription, index) => {
                    const stepProps: { completed?: boolean } = {};
                    const labelProps: {
                        optional?: React.ReactNode;
                    } = {};
                    if (stepDescription.isOptional) {
                        labelProps.optional = (
                            <Typography variant="caption">Optionnel</Typography>
                        );
                    }
                    return (
                        <Step key={stepDescription.stepTitle} {...stepProps}>
                            <StepLabel {...labelProps}>{stepDescription.stepTitle}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
        </Box>
    );
}

export default CheckoutStepper;