import React, {useState} from "react";
import styles from "./SocietyHomepageArticles.module.css";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ArticleCard from "../../articleCard/ArticleCard";
import {Button, Grid2} from "@mui/material";
import useSociety from "../../../hooks/useSociety";
import AddArticleModal from "../../societyAdmin/societyAdminArticles/addArticleModal/AddArticleModal";
import useDataFetch from "../../../hooks/useDataFetch";
import {ProductDTO} from "../../societyAdmin/societyAdminArticles/societyAdminArticlesList/SocietyAdminArticlesList";
import {useSectionsRefs} from "../../mainWrapper/MainWrapper";
import {useNavigate} from "react-router-dom";

const SocietyHomepageArticles = () => {

    const {society} = useSociety();

    const [open, setOpen] = useState<boolean>(false);

    const {data: articles} = useDataFetch<ProductDTO[]>(`/societies/${society.id}/products`, true, true);

    const {articlesSection} = useSectionsRefs();

    const navigate = useNavigate();

    return (
        <>
            <Box
                ref={articlesSection}
                sx={{
                    backgroundColor: "secondary.main",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <div className={styles.content}>
                    <Typography
                        sx={{
                            color: '#ffffff',
                            fontSize: {xs: 30, sm: 40},
                        }}
                        variant={"firstVariant"}
                    >
                        ARTICLES
                    </Typography>

                    {articles && articles.length === 0 && (
                        <Typography
                            sx={{
                                color: '#ffffff',
                                fontSize: {xs: 22, sm: 26},
                            }}
                            variant={"firstVariant"}
                        >
                            Aucun article n'est disponible actuellement. Revenez plus tard !
                        </Typography>
                    )}


                    {articles && (
                        <Grid2 container spacing={6} mt={2}>
                            {articles.slice(0,4).map((article: ProductDTO) => (
                                <ArticleCard key={article.id} article={article}/>
                            ))}
                        </Grid2>
                    )}

                    <Grid2 container>
                        <Grid2 size={12}>
                            {society.secured && (
                                <Button
                                    sx={{
                                        mt: 4,
                                    }}
                                    variant={"fourthVariant"}
                                    onClick={() => setOpen(true)}
                                >
                                    Ajouter un article
                                </Button>
                            )}
                        </Grid2>
                        <Grid2 size={12}>
                            <Button
                                sx={{
                                    mt: 6,
                                    fontSize: {xs: 16, sm: 18, md: 20},
                                }}
                                variant="fourthVariant"
                                onClick={() => navigate('/articles')}
                            >
                                Voir tous les articles
                            </Button>
                        </Grid2>
                    </Grid2>

                </div>
            </Box>
            <AddArticleModal open={open} setOpen={setOpen}/>
        </>
    );
}

export default SocietyHomepageArticles