import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import CustomAvatar from "./avatar/Avatar";
import {useNavigate} from "react-router-dom";
import Typography from "@mui/material/Typography";
import LoginModal from "../loginModal/LoginModal";
import useAuth from "../../hooks/useAuth";
import useLogout from "../../hooks/useLogout";
import styles from "./AppBar.module.css";
import {NavItemAppBar} from "../mainWrapper/MainWrapper";
import useSociety from "../../hooks/useSociety";
import {Badge, Grid2} from "@mui/material";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import InstagramIcon from "@mui/icons-material/Instagram";
import AccountCircle from "@mui/icons-material/AccountCircle";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import {ShoppingBasketOutlined} from "@mui/icons-material";
import {useCart, useCartOverlay} from "../../hooks/useCart";

interface DrawerAppBarProps {
    baseWindow?: () => Window; // TODO  not sure what this is used for... to remove ?
    navItems: NavItemAppBar[];
    displayRegisterSocietyButton?: boolean;
    displayLoginButton?: boolean;
    displayMobileMenu?: boolean;
    displayCart?: boolean;
    redirectToLootyHomepage?: boolean;
}

const drawerWidth = 240;

const DrawerAppBar = ({
                          baseWindow,
                          navItems,
                          displayRegisterSocietyButton = true,
                          displayLoginButton = true,
                          displayMobileMenu = true,
                          displayCart = false,
                          redirectToLootyHomepage = false,
                      }: DrawerAppBarProps) => {

    const {society} = useSociety();

    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [logoImgError, setLogoImgError] = React.useState(false);


    const {auth} = useAuth();
    const logout = useLogout();

    const cart = useCart();

    const navigate = useNavigate();

    const {openCart} = useCartOverlay();

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const handleLogout = () => {
        logout();
        // eslint-disable-next-line no-restricted-globals
        window.location.reload();
    }

    const redirect = () => {
        if (redirectToLootyHomepage) {
            window.location.href = 'https://looty.ch'
        } else {
            document.location.href = '/';
        }
    }

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{p: 1}}>
            {society.id !== undefined ? (
                <Typography
                    onClick={redirect}
                    sx={{
                        fontSize: {xs: 18, sm: 20, md: 22},
                        ":hover": {cursor: "pointer"},
                    }}
                    variant={"secondVariant"}>
                    {society.name}
                </Typography>
            ) : (
                <Box
                    className={styles.logoWrapper}
                    component="img"
                    sx={{display: {md: 'none'}, width: 80}}
                    src={'/looty-logo.png'}
                    alt="looty logo"
                    onClick={redirect}
                />
            )}

            <Divider/>
            <List>
                {navItems.map((item) => (
                    <ListItem key={item.title} disablePadding>
                        <ListItemButton
                            sx={{p: 0}}
                            onClick={() => {
                                item.scrollRef?.current?.scrollIntoView({
                                    behavior: 'smooth'
                                })
                            }}
                        >
                            <ListItemText primary={item.title}/>
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
            <Divider/>
            {auth.token ?
                <Button
                    sx={{mt: 1, mb: 1}}
                    variant="secondVariant"
                    onClick={() => handleLogout()}
                >
                    Se déconnecter
                </Button>
                : <>
                    {displayRegisterSocietyButton && (
                        <Button
                            sx={{mt: 1}}
                            variant="thirdVariant"
                            onClick={() => navigate('/info')}
                        >
                            Inscrire ma société
                        </Button>
                    )}

                    {displayLoginButton && (
                        <Button
                            sx={{mt: 1, mb: 1}}
                            variant="secondVariant"
                            onClick={() => setOpen(true)}
                        >
                            Se connecter
                        </Button>
                    )}
                </>}
            <Divider/>
            <IconButton
                aria-label="Instagram"
                variant="firstVariant"
            >
                <InstagramIcon/>
            </IconButton>
            <IconButton
                aria-label="LinkedIn"
                variant="firstVariant"
            >
                <LinkedInIcon/>
            </IconButton>
            <Divider/>
            <Typography sx={{height: 20}} variant={"firstVariant"}>
                info@looty.ch
            </Typography>
            <Typography sx={{height: 20}} variant={"firstVariant"}>
                Looty © 2024
            </Typography>
        </Box>
    );

    const container = baseWindow !== undefined ? () => baseWindow().document.body : undefined;

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <Box sx={{display: 'flex'}}>
                    <CssBaseline/>
                    <AppBar component="nav">
                        <Toolbar
                            sx={{
                                width: "100%",
                                maxWidth: 1200,
                                mx: "auto",
                            }}
                        >
                            <Grid2
                                container
                                sx={{
                                    width: "100%",
                                }}
                            >

                                <Grid2
                                    size={{xs: 3, sm: 3, md: displayRegisterSocietyButton ? 4 : 3}}
                                    sx={{
                                        alignContent: "center",
                                        textAlign: "left",
                                    }}
                                >
                                    {displayMobileMenu && (
                                        <IconButton
                                            variant="firstVariant"
                                            aria-label="open drawer"
                                            edge="start"
                                            onClick={handleDrawerToggle}
                                            sx={{mr: 2, display: {md: 'none'}}}
                                        >
                                            <MenuIcon/>
                                        </IconButton>)}
                                    {displayMobileMenu && (
                                        society.id !== undefined ?
                                            (
                                                !logoImgError ? (
                                                    <Box
                                                        display="flex"
                                                        justifyContent="center"
                                                        alignItems={"center"}
                                                        overflow="hidden"
                                                        sx={{
                                                            display: {xs: 'none', sm: 'none', md: 'block'},
                                                            width: '100%',
                                                            height: '70px',
                                                            alignItems: 'center',
                                                            alignContent: 'center',
                                                            padding: 1,
                                                            backgroundColor: '#ffffff',
                                                        }}
                                                    >
                                                        <img
                                                            className={styles.societyImage}
                                                            // src={'https://brasseriedupoyet.ch/wp-content/uploads/2024/01/Logo-brasserie-depuis-2007_brun_clair_transparent-300x250.png'}
                                                            // src={'https://fcmontbrelloz.ch/wp-content/uploads/2024/05/Logo-final1_FCM.png'}
                                                            // src={'https://prod-migrosindustrie-data.storage.googleapis.com/files/sites/4/20230424063528/Logo_Elsa_2023_MI_Cobranding_pos_cropped-2048x490.png'}
                                                            // src={'https://le-de.cdn-website.com/faaed07ef2b14ebdaf48b013a225161f/dms3rep/multi/opt/Logo_GDP-240w.png'}
                                                            // src={'https://www.reflexsophie.ch/wp-content/uploads/2024/08/logo-texte-CMJN-1-1536x1005.png'}
                                                            src={`https://looty.ch:8443/api/v1/societies/${society.id}/image`}
                                                            onError={({currentTarget}) => {
                                                                setLogoImgError(true);
                                                            }}
                                                            alt="society"
                                                            onClick={redirect}
                                                        />
                                                    </Box>
                                                ) : (
                                                    <Typography
                                                        variant={"firstVariant"}
                                                        sx={{
                                                            display: {xs: 'none', sm: 'none', md: 'block'},
                                                            ":hover": {cursor: "pointer"},
                                                        }}
                                                        onClick={redirect}
                                                    >
                                                        {society.name}
                                                    </Typography>
                                                )
                                            ) :
                                            (
                                                <Box
                                                    className={styles.logoWrapper}
                                                    component="img"
                                                    sx={{display: {xs: 'none', sm: 'none', md: 'block'}, width: 120}}
                                                    src={'/looty-logo.png'}
                                                    alt="looty logo"
                                                    onClick={redirect}
                                                />
                                            )
                                    )}
                                </Grid2>


                                <Grid2
                                    size={{xs: 6, sm: 6, md: displayRegisterSocietyButton ? 4 : 6}}
                                    sx={{
                                        alignContent: "center",
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: {sm: 'block', md: displayMobileMenu ? 'none' : 'block'},
                                            flexGrow: 1
                                        }}
                                    >
                                        {society.id !== undefined ?
                                            (!logoImgError ?
                                                    (
                                                        <Box
                                                            display="flex"
                                                            justifyContent="center"
                                                            alignItems={"center"}
                                                            overflow="hidden"
                                                            sx={{
                                                                width: '100%',
                                                                height: '70px',
                                                                alignItems: 'center',
                                                                alignContent: 'center',
                                                                padding: 1,
                                                                backgroundColor: '#ffffff',
                                                            }}
                                                        >
                                                            <img
                                                                className={styles.societyImage}
                                                                src={`https://looty.ch:8443/api/v1/societies/${society.id}/image`}
                                                                onError={({currentTarget}) => {
                                                                    setLogoImgError(true);
                                                                }}
                                                                alt="society"
                                                                onClick={redirect}
                                                            />
                                                        </Box>
                                                    ) : (
                                                        <Typography
                                                            variant={"firstVariant"}
                                                            onClick={redirect}
                                                            sx={{
                                                                ":hover": {cursor: "pointer"},
                                                            }}
                                                        >
                                                            {society.name}
                                                        </Typography>
                                                    )
                                            ) : (
                                                <Box
                                                    className={styles.logoWrapper}
                                                    component="img"
                                                    sx={{width: 120}}
                                                    src={'/looty-logo.png'}
                                                    alt="looty logo"
                                                    onClick={redirect}
                                                >
                                                </Box>
                                            )}

                                    </Box>
                                    {navItems.length > 0 && (
                                        <Box sx={{display: {xs: 'none', sm: 'none', md: 'block'}}} flexGrow={1}>
                                            {navItems.map((item) => (
                                                <Button
                                                    key={item.title}
                                                    sx={{color: '#000000'}}
                                                    onClick={() => {
                                                        item.scrollRef?.current?.scrollIntoView({
                                                            behavior: 'smooth'
                                                        })
                                                    }
                                                    }
                                                >
                                                    {item.title}
                                                </Button>
                                            ))}
                                        </Box>
                                    )}
                                </Grid2>

                                <Grid2
                                    size={{xs: 3, sm: 3, md: displayRegisterSocietyButton ? 4 : 3}}
                                    sx={{
                                        alignContent: "center",
                                        textAlign: "right",
                                    }}
                                >
                                    {displayMobileMenu && (
                                        auth.token ? <CustomAvatar/> : (
                                            <>
                                                {displayRegisterSocietyButton && (
                                                    <Button
                                                        sx={{
                                                            display: {xs: 'none', sm: 'none', md: 'inline-flex'},
                                                            marginRight: '10px',
                                                        }}
                                                        variant="thirdVariant"
                                                        onClick={() => navigate('/info')}
                                                    >
                                                        Inscrire ma société
                                                    </Button>
                                                )}

                                                {displayLoginButton && (
                                                    <IconButton
                                                        sx={{
                                                            display: {xs: 'inline-flex'},
                                                            paddingRight: {xs: '0px', sm: '0px'},
                                                        }}
                                                        aria-label="Instagram"
                                                        variant="firstVariant"
                                                        onClick={() => setOpen(true)}
                                                    >
                                                        <AccountCircle
                                                            sx={{
                                                                fontSize: {xs: '25px', sm: '30px'},
                                                                padding: '0px',
                                                            }}
                                                        />
                                                    </IconButton>
                                                    //<Button
                                                    //sx={{
                                                    //      display: {xs: 'none', sm: 'none', md: 'inline-flex'}
                                                    //  }}
                                                    //  variant="secondVariant"
                                                    //  onClick={() => setOpen(true)}
                                                    //>
                                                    //   Se connecter
                                                    //</Button>
                                                )}
                                                {society.id !== undefined && displayCart && (
                                                    <IconButton
                                                        sx={{
                                                            display: {xs: 'inline-flex'},
                                                            paddingRight: {xs: '0px', sm: '0px'},
                                                        }}
                                                        aria-label="Instagram"
                                                        variant="firstVariant"
                                                        onClick={() => openCart()}
                                                    >
                                                        <Badge badgeContent={cart.getItemsNumber()} color={'secondary'}>
                                                            <ShoppingBasketOutlined
                                                                sx={{
                                                                    fontSize: {xs: '30px', sm: '30px'},
                                                                    padding: '0px',
                                                                }}
                                                            />
                                                        </Badge>

                                                    </IconButton>
                                                )}
                                            </>
                                        )
                                    )}
                                </Grid2>
                            </Grid2>
                        </Toolbar>
                    </AppBar>
                    {displayMobileMenu && (
                        <nav>
                            <Drawer
                                container={container}
                                variant="temporary"
                                open={mobileOpen}
                                onClose={handleDrawerToggle}
                                ModalProps={{
                                    keepMounted: true, // Better open performance on mobile.
                                }}
                                sx={{
                                    display: {sm: 'block', md: 'none'},
                                    '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth},
                                }}
                            >
                                {drawer}
                            </Drawer>
                        </nav>)}

                    <Toolbar/>
                </Box>
                <LoginModal open={open} setOpen={setOpen}/>
            </div>
        </div>
    );
}

export default DrawerAppBar;
