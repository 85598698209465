import {Navigate, Outlet, useLocation} from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import useSociety from "../../hooks/useSociety";
import {useSectionsRefs} from "../mainWrapper/MainWrapper";

const RequireAuth = () => {
    const {auth} = useAuth();
    const location = useLocation();
    const {society} = useSociety();

    const {setNavItems, setDisplayRegisterSocietyButton, setDisplayCart} = useSectionsRefs();

    return (
        (auth?.token && auth?.societyId === society.id)
            ? <Outlet context={{setNavItems, setDisplayRegisterSocietyButton, setDisplayCart}}/>
            : <Navigate to="/unauthorized" state={{from: location}} replace/>
    )
}

export default RequireAuth;