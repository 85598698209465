import React from "react";
import {Grid2} from "@mui/material";
import Typography from "@mui/material/Typography";
import styles from "./CartOverlayItem.module.css";
import {ProductDTO} from "../../societyAdmin/societyAdminArticles/societyAdminArticlesList/SocietyAdminArticlesList";
import Box from "@mui/material/Box";
import useSociety from "../../../hooks/useSociety";
import IconButton from "@mui/material/IconButton";
import {AddRounded, DeleteOutline, RemoveRounded} from "@mui/icons-material";
import {useCart} from "../../../hooks/useCart";
import useFormatNumber from "../../../hooks/useFormatNumber";

interface CartOverlayItemProps {
    product: ProductDTO,
    quantity: number,
}

const CartOverlayItem = ({product, quantity}: CartOverlayItemProps) => {

    const [imgError, setImgError] = React.useState(false);

    const {society} = useSociety();

    const {cartItems, removeFromCart, updateCartItemQuantity} = useCart();

    const {formatCentsToPrice} = useFormatNumber();

    return (
        <Grid2 container mb={0}>
            <Grid2 size={4}>
                <Box
                    display="flex"
                    justifyContent="center"
                    overflow="hidden"
                    sx={{
                        height: '125px',
                        mb: 2,
                        alignItems: 'center',
                        alignContent: 'center',
                        paddingBottom: '0px',
                        backgroundColor: '#ffffff',
                    }}
                >
                    <img
                        className={styles.articleImage}
                        src={imgError ? 'missing-image.png' : `https://looty.ch:8443/api/v1/societies/${society.id}/products/${product.id}/image`}
                        onError={({currentTarget}) => {
                            setImgError(true);
                        }}
                        alt="society"/>
                </Box>
            </Grid2>
            <Grid2
                size={8}
                container
                sx={{
                    pl: 2,
                }}
            >
                <Grid2 size={12}>
                    <Typography
                        variant={"firstVariant"}
                        sx={{
                            fontSize: {xs: 18, sm: 18, md: 18},
                        }}
                    >
                        {product.name}
                    </Typography>
                </Grid2>
                <Grid2 size={12}>
                    <Typography
                        variant={"firstVariant"}
                        sx={{
                            fontSize: {xs: 18, sm: 18, md: 18},
                        }}
                    >
                        {formatCentsToPrice(product.price*quantity)}.-
                    </Typography>
                </Grid2>
                <Grid2 size={12}>
                    <IconButton
                        aria-label="Decrement"
                        variant="thirdVariant"
                        sx={{
                            pl: '0px',
                            pr: '4px',
                        }}
                        onClick={() => {
                            quantity > 1 && updateCartItemQuantity(product.id, quantity - 1)
                        }}
                    >
                        <RemoveRounded
                            sx={{
                                fontSize: {xs: '30px', sm: '30px'},
                                padding: '0px',
                            }}
                        />
                    </IconButton>
                    <Typography
                        variant={"firstVariant"}
                        sx={{
                            fontFamily: '',
                            fontSize: {xs: 18},
                            display: 'inline-flex'
                        }}
                    >
                        {quantity}
                    </Typography>
                    <IconButton
                        aria-label="Increment"
                        variant="thirdVariant"
                        onClick={() => updateCartItemQuantity(product.id, quantity + 1)}
                        sx={{
                            pl: '2px',
                        }}
                    >
                        <AddRounded
                            sx={{
                                fontSize: {xs: '30px', sm: '30px'},
                                padding: '0px',
                            }}
                        />

                    </IconButton>
                    <IconButton
                        aria-label="Remove"
                        variant="thirdVariant"
                        onClick={() => removeFromCart(product.id)}
                    >
                        <DeleteOutline
                            sx={{
                                fontSize: {xs: '30px', sm: '30px'},
                                padding: '0px',
                            }}
                        />

                    </IconButton>
                </Grid2>
            </Grid2>
        </Grid2>
    );
}

export default CartOverlayItem