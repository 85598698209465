import React from "react";
import {AdvancedMarker, Map} from "@vis.gl/react-google-maps";
import useSociety from "../../../../hooks/useSociety";

const SocietyHomepageContactMap = () => {
    const {society} = useSociety();

    return (
        <>
        {society.societyContactDetails?.lng && society.societyContactDetails.lat && (
            <Map
                mapId={"97ef1108e25bae36"}
                style={{width: '100%', height: '50vh'}}
                defaultCenter={{lat: society.societyContactDetails.lat, lng: society.societyContactDetails.lng}}
                defaultZoom={18}
                gestureHandling={'cooperative'}
                disableDefaultUI={false}
            >
                <AdvancedMarker
                    position={{lat: society.societyContactDetails.lat, lng: society.societyContactDetails.lng}}
                />
            </Map>
        )}
        </>
    );
}

export default SocietyHomepageContactMap