import React, {useState} from "react";
import styles from "./SocietyAdminTheme.module.css"
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {Grid2, Radio} from "@mui/material";
import Button from "@mui/material/Button";
import useSociety from "../../../hooks/useSociety";
import useDataPut from "../../../hooks/useDataPut";
import {SocietyDTO} from "../../../context/SocietyProvider";

const themes = [
    {
        title: 'CLASSIC',
        colors: ['#543B26', '#B0754D', '#D6B59C'],
    },
    {
        title: 'BLUE',
        colors: ['#011F4B', '#354476', '#C0CAFF'],
    },
    {
        title: 'GREEN',
        colors: ['#234D20', '#457040', '#B4E3AC'],
    },
]

const SocietyAdminTheme = () => {
    const {society} = useSociety();

    const [selectedTheme, setSelectedTheme] = useState<string>(society.theme || 'CLASSIC');

    const {putData} = useDataPut<SocietyDTO>(`/societies/${society.id}`, {}, true, false);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedTheme(event.target.value);
    };

    const updateTheme = () => {
        putData(undefined, {
            societyId: society.id,
            societyName: society.name,
            societyDescription: society.description,
            societyTheme: selectedTheme,
        }).then((data) => {
            window.location.reload();
        })
    }

    return (
        <Box sx={{
            width: "100%",
            backgroundColor: 'secondary.main',
            alignContent: 'center'
        }}>

            <Box
                sx={{
                    backgroundColor: "secondary.main",
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                <div className={styles.content}>
                    <div className={styles.realContent}>
                        <Typography
                            variant={"secondVariant"}
                            sx={{
                                fontSize: {xs: 25, sm: 30}
                            }}
                        >
                            Thème de votre page
                        </Typography>

                        <Grid2 container mt={2} mb={2} rowSpacing={4}>

                            {themes.map((theme: any) => {
                                return (
                                    <Grid2 key={theme.title} size={{xs: 12, sm: 12, md: 4}} container>
                                        <Grid2 size={2} container sx={{justifyContent: 'center'}} offset={2}>
                                            <Radio
                                                checked={selectedTheme === theme.title}
                                                onChange={handleChange}
                                                value={theme.title}
                                                name="radio-buttons"
                                                inputProps={{'aria-label': 'A'}}
                                            />
                                        </Grid2>
                                        {theme.colors.map((color: string) => {
                                            return (
                                                <Grid2 key={theme.title + color} size={2} container
                                                       sx={{justifyContent: 'center', alignContent: 'center'}}>
                                                    <Box
                                                        sx={{
                                                            width: 35,
                                                            height: 25,
                                                            backgroundColor: color,
                                                        }}
                                                    />
                                                </Grid2>
                                            )
                                        })}
                                    </Grid2>
                                );
                            })}

                        </Grid2>

                        <Button
                            variant={"firstVariant"}
                            sx={{
                                mb: 2,
                            }}
                            onClick={() => updateTheme()}
                        >
                            Modifier le thème
                        </Button>

                    </div>
                </div>

            </Box>


        </Box>
    );
}

export default SocietyAdminTheme