import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import {AuthProvider} from "./context/AuthProvider";
import {SocietyProvider} from "./context/SocietyProvider";
import ScrollToTop from "./components/scrollToTop/ScrollToTop";
import ShoppingCartProvider from "./context/ShoppingCartProvider";
import {APIProvider} from "@vis.gl/react-google-maps";
import {CustomThemeProvider} from "./context/CustomThemeProvider";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <BrowserRouter>
        <ScrollToTop/>
        <SocietyProvider>
            <APIProvider apiKey={process.env.REACT_APP_GEOCODER_API_KEY || ''}>
                <ShoppingCartProvider>
                    <AuthProvider>
                        <CustomThemeProvider>
                            <App/>
                        </CustomThemeProvider>
                    </AuthProvider>
                </ShoppingCartProvider>
            </APIProvider>
        </SocietyProvider>
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
