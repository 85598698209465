import React, {useState} from "react";
import styles from "./SocietyHomepageContact.module.css";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {Button, Stack} from "@mui/material";
import CustomTextField from "../../customMUIComponents/CustomTextField";
import {useSectionsRefs} from "../../mainWrapper/MainWrapper";
import useSociety from "../../../hooks/useSociety";
import SocietyHomepageContactMap from "./societyHomepageContactMap/SocietyHomepageContactMap";
import IconButton from "@mui/material/IconButton";
import Edit from "@mui/icons-material/Edit";
import Done from "@mui/icons-material/Done";
import Close from "@mui/icons-material/Close";
import useDataPut from "../../../hooks/useDataPut";
import {SocietyDTO} from "../../../context/SocietyProvider";

const SocietyHomepageContact = () => {
    const {society} = useSociety();

    const [firstnameForm, setFirstnameForm] = useState<string>('');
    const [lastnameForm, setLastnameForm] = useState<string>('');
    const [societyForm, setSocietyForm] = useState<string>('');
    const [emailForm, setEmailForm] = useState<string>('');
    const [phoneNumberForm, setPhoneNumberForm] = useState<string>('');
    const [messageForm, setMessageForm] = useState<string>('');

    const [editSocietyAddress, setEditSocietyAddress] = useState<boolean>(false);
    const [newSocietyZip, setNewSocietyZip] = useState<string>(society.societyContactDetails?.zip!);
    const [newSocietyCity, setNewSocietyCity] = useState<string>(society.societyContactDetails?.city!);
    const [newSocietyStreet, setNewSocietyStreet] = useState<string>(society.societyContactDetails?.street!);
    const [newSocietyStreetNumber, setNewSocietyStreetNumber] = useState<string>(society.societyContactDetails?.streetNumber!);

    const {contactSection} = useSectionsRefs();

    const {putData} = useDataPut<SocietyDTO>(`/societies/${society.id}/contact-details`, {}, true, false);


    const handleEditSocietyName = () => {
        setEditSocietyAddress(!editSocietyAddress);
        putData(undefined, {
            societyId: society.id,
            zip: newSocietyZip,
            city: newSocietyCity,
            street: newSocietyStreet,
            streetNumber: newSocietyStreetNumber,
        }).then((data) => {
            window.location.reload();
        })
    }

    const submit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        alert('Work in progress');
    }

    return (
        <Box
            ref={contactSection}
            sx={{
                backgroundColor: "tertiary.main",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <div className={styles.content}>
                <Typography
                    sx={{
                        color: '#ffffff',
                        fontSize: {xs: 30, sm: 40},
                    }}
                    variant={"firstVariant"}
                >
                    CONTACT
                </Typography>

                <Box>
                    {!editSocietyAddress ? (
                        <Box>
                            <Typography
                                sx={{
                                    color: '#ffffff',
                                    fontSize: {xs: 18, sm: 20, md: 24},
                                }}
                                variant={"firstVariant"}
                            >
                                {society.societyContactDetails?.zip} {society.societyContactDetails?.city}
                            </Typography>
                            <Typography
                                sx={{
                                    color: '#ffffff',
                                    fontSize: {xs: 18, sm: 20, md: 24},
                                }}
                                variant={"firstVariant"}
                            >
                                {society.societyContactDetails?.street} {society.societyContactDetails?.streetNumber}
                            </Typography>
                        </Box>
                    ) : (
                        <Box>
                            <Stack spacing={2} direction="row" sx={{marginBottom: 4}}>
                                <CustomTextField
                                    value={newSocietyZip}
                                    label="Code postal"
                                    setField={setNewSocietyZip}
                                    required/>
                                <CustomTextField
                                    value={newSocietyCity}
                                    label="Localité"
                                    setField={setNewSocietyCity}
                                    required/>
                            </Stack>
                            <Stack spacing={2} direction="row" sx={{marginBottom: 4}}>
                                <CustomTextField
                                    value={newSocietyStreet}
                                    label="Rue"
                                    setField={setNewSocietyStreet}
                                    required/>
                                <CustomTextField
                                    value={newSocietyStreetNumber}
                                    label="N° de rue"
                                    setField={setNewSocietyStreetNumber}
                                    required/>
                            </Stack>
                        </Box>
                    )}
                </Box>
                <Box sx={{
                    alignContent: 'center',
                    display: society.secured ? 'block' : 'none',
                }}>
                    {!editSocietyAddress ? (
                        <IconButton
                            aria-label="Instagram"
                            variant="secondVariant"
                            onClick={() => {
                                setNewSocietyZip(society.societyContactDetails?.zip!)
                                setNewSocietyCity(society.societyContactDetails?.city!)
                                setNewSocietyStreet(society.societyContactDetails?.street!)
                                setNewSocietyStreetNumber(society.societyContactDetails?.streetNumber!)
                                setEditSocietyAddress(true)
                            }}
                        >
                            <Edit/>
                        </IconButton>
                    ) : (
                        <>
                            <IconButton
                                aria-label="Instagram"
                                variant="secondVariant"
                                onClick={() => handleEditSocietyName()}
                            >
                                <Done/>
                            </IconButton>
                            <IconButton
                                aria-label="Instagram"
                                variant="secondVariant"
                                onClick={() => setEditSocietyAddress(false)}
                            >
                                <Close/>
                            </IconButton>
                        </>
                    )}
                </Box>

                <Box sx={{
                    width: '100%',
                    mb: 4,
                    mt: 2,
                }}>
                    <SocietyHomepageContactMap/>
                </Box>

                <form onSubmit={(e) => submit(e)}>
                    <Stack spacing={2} direction="row" sx={{marginBottom: 4}}>
                        <CustomTextField
                            value={firstnameForm}
                            label="Prénom"
                            setField={setFirstnameForm}
                            required/>
                        <CustomTextField
                            value={lastnameForm}
                            label="Nom"
                            setField={setLastnameForm}
                            required/>
                    </Stack>

                    <CustomTextField
                        value={societyForm}
                        label="Société" setField={setSocietyForm}/>

                    <Stack spacing={2} direction="row" sx={{marginTop: 4}}>
                        <CustomTextField
                            value={emailForm}
                            label="Email"
                            type="email"
                            setField={setEmailForm}
                            required/>
                        <CustomTextField
                            value={phoneNumberForm}
                            label="N° téléphone"
                            type="text"
                            setField={setPhoneNumberForm}
                            required/>
                    </Stack>

                    <CustomTextField
                        value={messageForm}
                        label="Votre message"
                        setField={setMessageForm}
                        required
                        multiline
                        rows={6}
                        mt={4}
                        mb={4}/>

                    <Button
                        variant="fourthVariant"
                        type="submit"
                        fullWidth
                    >
                        Envoyer
                    </Button>
                </form>

            </div>
        </Box>
    );
}

export default SocietyHomepageContact