const useFormatNumber = () => {
    const formatCentsToPrice = (priceInCents: number): string => {
        const toFixed = (Math.round(priceInCents) / 100).toFixed(2);
        const split = toFixed.split('.');
        if (split[1] === '00') {
            return split[0];
        }
        return toFixed;
    }

    return {formatCentsToPrice};
}

export default useFormatNumber;