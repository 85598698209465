import {createContext} from "react";

const CartOverlayContext = createContext({
    isCartOpen: false,
    openCart: () => {
    },
    closeCart: () => {
    },
});

export default CartOverlayContext;