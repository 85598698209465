import React, {useEffect} from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {useSectionsRefs} from "../mainWrapper/MainWrapper";
import Button from "@mui/material/Button";

const SocietyNotFound = () => {

    const {
        setNavItems,
        setDisplayLoginButton,
        setDisplayMobileMenu,
        setDisplayRegisterSocietyButton,
        setRedirectToLootyHomepage
    } = useSectionsRefs();

    useEffect(() => {
        setNavItems([])
        setDisplayRegisterSocietyButton(false);
        setDisplayLoginButton(false);
        setDisplayMobileMenu(false);
        setRedirectToLootyHomepage(true);
    }, [setNavItems, setDisplayRegisterSocietyButton, setDisplayLoginButton, setDisplayMobileMenu, setRedirectToLootyHomepage]);

    return (
        <Box
            sx={{
                width: '100%',
                backgroundColor: 'primary.main',
                alignContent: 'center'
            }}
        >
            <Typography
                sx={{
                    fontSize: {xs: 25, sm: 30},
                }}
            >
                Cette société n'existe pas. Ou pas encore ;)
            </Typography>
            <Button
                variant={"firstVariant"}
                sx={{
                    mt: 4,
                }}
                onClick={() => window.location.href = "https://looty.ch"}
            >
                Retour à a la page d'accueil
            </Button>
        </Box>
    );
}

export default SocietyNotFound