import React, {useState} from "react";
import styles from "./ArticleDetails.module.css"
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {useParams} from "react-router-dom";
import {Grid2} from "@mui/material";
import useSociety from "../../../../hooks/useSociety";
import useProduct from "../../../../hooks/useProduct";
import useFormatNumber from "../../../../hooks/useFormatNumber";
import Button from "@mui/material/Button";
import CartOverlay from "../../../../components/cartOverlay/CartOverlay";
import {useCart, useCartOverlay} from "../../../../hooks/useCart";
import AddArticleModal from "../../../../components/societyAdmin/societyAdminArticles/addArticleModal/AddArticleModal";

const ArticleDetails = () => {

    const {addToCart, getProductQuantity} = useCart();

    const {isCartOpen, closeCart} = useCartOverlay();

    const [open, setOpen] = useState<boolean>(false);

    const [imgError, setImgError] = React.useState(false);

    const {id} = useParams();
    const {society} = useSociety();

    const {product} = useProduct(id ? Number(id) : 1);

    const handleAddToCart = () => {
        if (!product.stockDefined || (getProductQuantity(product.id) + 1 <= product.stock)) {
            addToCart(product, 1);
        }
    }

    const {formatCentsToPrice} = useFormatNumber();

    return (
        <Box sx={{
            width: "100%",
            backgroundColor: 'secondary.main',
            alignContent: 'center'
        }}>

            <Box
                sx={{
                    backgroundColor: "secondary.main",
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                <div className={styles.content}>
                    <div className={styles.realContent}>
                        {product && <Grid2 container p={{xs: 2, sm: 2, md: 5}} columnSpacing={{xs: 0, sm: 0, md: 4}}
                                           minHeight={'500px'}>
                            <Grid2 container size={{xs: 12, sm: 12, md: 6}}>
                                {/*<Grid2 size={2} sx={{alignContent: 'center'}}>*/}
                                {/*    <IconButton*/}
                                {/*        aria-label="Instagram"*/}
                                {/*        variant="firstVariant"*/}
                                {/*        sx={{*/}
                                {/*            p: {xs: 0, sm: 0, md: '8px'},*/}
                                {/*        }}*/}
                                {/*    >*/}
                                {/*        <ChevronLeft sx={{*/}
                                {/*            fontSize: {xs: '30px'},*/}
                                {/*        }}/>*/}
                                {/*    </IconButton>*/}
                                {/*</Grid2>*/}
                                <Grid2 size={12}>
                                    <Box
                                        display="flex"
                                        justifyContent="center"
                                        alignItems={"center"}
                                        overflow="hidden"
                                        sx={{
                                            height: '100%',
                                            alignItems: 'center',
                                            alignContent: 'center',
                                            backgroundColor: '#ffffff',
                                        }}
                                    >
                                        <img
                                            className={styles.societyImage}
                                            src={imgError ? 'missing-image.png' : `https://looty.ch:8443/api/v1/societies/${society.id}/products/${id}/image`}
                                            onError={({currentTarget}) => {
                                                setImgError(true);
                                            }}
                                            alt="society"/>
                                    </Box>
                                </Grid2>
                                {/*<Grid2 size={2} sx={{alignContent: 'center'}}>*/}
                                {/*    <IconButton*/}
                                {/*        aria-label="Instagram"*/}
                                {/*        variant="firstVariant"*/}
                                {/*    >*/}
                                {/*        <ChevronRight sx={{*/}
                                {/*            fontSize: {xs: '30px'},*/}
                                {/*        }}/>*/}
                                {/*    </IconButton>*/}
                                {/*</Grid2>*/}
                            </Grid2>
                            <Grid2 container size={{xs: 12, sm: 12, md: 6}} mt={{xs: 6, sm: 6, md: 0}}>
                                <Grid2 size={12}>
                                    <Typography
                                        sx={{
                                            textAlign: "left",
                                            fontSize: {xs: 20, sm: 28},
                                        }}
                                        variant={"secondVariant"}
                                    >
                                        {product && product.name}
                                    </Typography>
                                </Grid2>
                                <Grid2 size={12} mt={{xs: 2, sm: 2, md: 0}}>
                                    <Typography
                                        sx={{
                                            textAlign: "left",
                                            fontSize: {xs: 20, sm: 28},
                                        }}
                                        variant={"secondVariant"}
                                    >
                                        {product && formatCentsToPrice(product.price)}.-
                                    </Typography>
                                </Grid2>
                                <Grid2 size={12} mt={{xs: 2, sm: 2, md: 0}}>
                                    <Typography
                                        sx={{
                                            textAlign: "left",
                                            fontSize: {xs: 18, sm: 20},
                                        }}
                                        variant={"secondVariant"}
                                    >
                                        {product && "Ceci est une courte description de mon produit."}
                                    </Typography>
                                </Grid2>
                                <Grid2 size={12} mt={{xs: 2, sm: 2, md: 0}}>
                                    <Typography
                                        sx={{
                                            textAlign: "left",
                                            fontSize: {xs: 18, sm: 20},
                                        }}
                                        color={!product.stockDefined || product.stock > 10 ? 'success' : 'warning'}
                                        fontSize={'12px'}
                                    >
                                        {!product.stockDefined ? 'Disponible' : product.stock > 10 ? 'Plus de 10 pièces disponibles' : product.stock === 1 ? '1 pièce disponible' : `${product.stock} pièces disponibles`}
                                    </Typography>
                                </Grid2>
                                <Grid2 size={12} mt={{xs: 2, sm: 2, md: 0}}>
                                    <Button
                                        fullWidth
                                        variant={"fourthVariant"}
                                        onClick={() => handleAddToCart()}
                                    >
                                        Ajouter au panier
                                    </Button>
                                </Grid2>
                                {society.secured && (
                                    <Grid2 size={12} mt={{xs: 2, sm: 2, md: 0}}>
                                        <Button
                                            fullWidth
                                            variant={"firstVariant"}
                                            onClick={() => setOpen(true)}
                                        >
                                            Modifier l'article
                                        </Button>
                                    </Grid2>
                                )}
                            </Grid2>
                        </Grid2>
                        }
                    </div>
                </div>

            </Box>

            <AddArticleModal open={open} setOpen={setOpen} product={product}/>
            <CartOverlay isCartOpen={isCartOpen} closeCart={closeCart}/>

        </Box>
    );
}

export default ArticleDetails