import React, {useState} from "react";
import styles from "./SocietyAdminLogo.module.css"
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import useSociety from "../../../hooks/useSociety";
import Button from "@mui/material/Button";
import {Grid2} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {DeleteOutline} from "@mui/icons-material";
import UseDataPut from "../../../hooks/useDataPut";

const SocietyAdminTheme = () => {
    const {society} = useSociety();

    const [image, setImage] = useState<File | null>(null);
    const [imageName, setImageName] = useState<string>(society.image || '');
    const [keepImage, setKeepImage] = useState<boolean>(!!society?.image);

    const {putData} = UseDataPut(`/societies/${society.id}/image`, {}, true, false, true);

    const updateLogo = () => {
        const formData: any = new FormData();
        if (image) {
            formData.append('image', image);
        }
        formData.append('keepImage', keepImage);

        putData(undefined, formData)
            .then((response) => window.location.reload());
    }

    return (
        <Box sx={{
            width: "100%",
            backgroundColor: 'secondary.main',
            alignContent: 'center'
        }}>

            <Box
                sx={{
                    backgroundColor: "secondary.main",
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                <div className={styles.content}>
                    <div className={styles.realContent}>
                        <Typography
                            variant={"secondVariant"}
                            sx={{
                                fontSize: {xs: 25, sm: 30}
                            }}
                        >
                            Logo de votre société
                        </Typography>

                        {(imageName != null && keepImage) || image ? (
                            <Box
                                margin={"auto"}
                                width={"fit-content"}
                            >
                                <Grid2 container>
                                    <Grid2>
                                        <Typography
                                            mt={1}
                                            variant={"secondVariant"}
                                        >
                                            {image ? image.name : society.image}
                                        </Typography>
                                    </Grid2>
                                    <Grid2>
                                        {(image || keepImage) && (
                                            <IconButton
                                                aria-label="Remove"
                                                variant="thirdVariant"
                                                onClick={() => {
                                                    setImage(null);
                                                    setKeepImage(false);
                                                }}
                                            >
                                                <DeleteOutline
                                                    sx={{
                                                        fontSize: {xs: '25px', sm: '25px'},
                                                        padding: '0px',
                                                    }}
                                                />

                                            </IconButton>
                                        )}
                                    </Grid2>
                                </Grid2>
                            </Box>
                        ) : (
                            <Box>
                                <input
                                    accept="image/*"
                                    style={{display: 'none'}}
                                    id="raised-button-file"
                                    type="file"
                                    onChange={(event) => {
                                        if (event.target.files && event.target.files.length > 0) {
                                            if (event.target.files[0].size > 10485760) {
                                                alert('Fichier trop volumineux. Max 10Mb.')
                                            } else {
                                                setImage(event.target.files[0]);
                                            }
                                        }
                                    }}
                                />
                                <label htmlFor="raised-button-file">
                                    <Button variant="secondVariant" component="span" sx={{mt: 2, mb: 2}}>
                                        Ajouter un logo
                                    </Button>
                                </label>
                            </Box>
                        )}

                        {(image || (society.image && !keepImage)) && (
                            <Button
                                variant={"firstVariant"}
                                sx={{
                                    mb: 2,
                                }}
                                onClick={() => updateLogo()}
                            >
                                Valider les changements
                            </Button>
                        )}
                    </div>
                </div>

            </Box>
        </Box>
    );
}

export default SocietyAdminTheme