import {Outlet, useOutletContext} from "react-router-dom";
import React, {MutableRefObject, useEffect, useRef, useState} from "react";
import Footer from "../footer/Footer";
import ResponsiveAppBar from "../appBar/AppBar";
import ResponsiveAppBar2 from "../appBar/AppBar2";
import styles from "./MainWrapper.module.css";
import useCustomTheme from "../../hooks/useCustomTheme";

export interface NavItemAppBar {
    title: string,
    scrollRef?: MutableRefObject<HTMLDivElement | null>,
}

export interface ContextType {
    societiesSection: MutableRefObject<HTMLDivElement | null>;
    aboutSection: MutableRefObject<HTMLDivElement | null>;
    contactSection: MutableRefObject<HTMLDivElement | null>;
    servicesSection: MutableRefObject<HTMLDivElement | null>;
    ratesSection: MutableRefObject<HTMLDivElement | null>;
    societyDescriptionSection: MutableRefObject<HTMLDivElement | null>;
    articlesSection: MutableRefObject<HTMLDivElement | null>;
    navItems: NavItemAppBar[];
    setNavItems: (navItems: NavItemAppBar[]) => void;
    setDisplayRegisterSocietyButton: (displayRegisterSocietyButton: boolean) => void;
    setDisplayLoginButton: (displayLoginButton: boolean) => void;
    setDisplayMobileMenu: (displayMobileMenu: boolean) => void;
    setDisplayCart: (displayCart: boolean) => void;
    setRedirectToLootyHomepage: (redirectToLootyHomepage: boolean) => void;
}

const MainWrapper = () => {

    const [navItems, setNavItems] = useState<NavItemAppBar[]>([]);
    const [displayRegisterSocietyButton, setDisplayRegisterSocietyButton] = useState<boolean>(true);
    const [displayLoginButton, setDisplayLoginButton] = useState<boolean>(true);
    const [displayMobileMenu, setDisplayMobileMenu] = useState<boolean>(true);
    const [displayCart, setDisplayCart] = useState<boolean>(true);
    const [redirectToLootyHomepage, setRedirectToLootyHomepage] = useState<boolean>(false);

    const societiesSection: MutableRefObject<HTMLDivElement | null> = useRef<HTMLDivElement | null>(null);
    const aboutSection: MutableRefObject<HTMLDivElement | null> = useRef<HTMLDivElement | null>(null);
    const contactSection: MutableRefObject<HTMLDivElement | null> = useRef<HTMLDivElement | null>(null);

    const servicesSection: MutableRefObject<HTMLDivElement | null> = useRef<HTMLDivElement | null>(null);
    const ratesSection: MutableRefObject<HTMLDivElement | null> = useRef<HTMLDivElement | null>(null);

    const articlesSection: MutableRefObject<HTMLDivElement | null> = useRef<HTMLDivElement | null>(null);
    const societyDescriptionSection: MutableRefObject<HTMLDivElement | null> = useRef<HTMLDivElement | null>(null);

    return (
        <>
            <ResponsiveAppBar2
                navItems={navItems}
                displayRegisterSocietyButton={displayRegisterSocietyButton}
                displayLoginButton={displayLoginButton}
                displayMobileMenu={displayMobileMenu}
                displayCart={displayCart}
                redirectToLootyHomepage={redirectToLootyHomepage}
            />
            <div className={styles.outletWrapper}>
                <Outlet context={{
                    societiesSection,
                    aboutSection,
                    contactSection,
                    servicesSection,
                    ratesSection,
                    articlesSection,
                    societyDescriptionSection,
                    setNavItems,
                    setDisplayRegisterSocietyButton,
                    setDisplayLoginButton,
                    setDisplayMobileMenu,
                    setDisplayCart,
                    setRedirectToLootyHomepage,
                }}/>
            </div>
            <Footer/>
        </>
    )
}

export function useSectionsRefs() {
    return useOutletContext<ContextType>();
}

export default MainWrapper;