import React from "react";
import styles from "./SocietiesList.module.css";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {Button, Grid2} from "@mui/material";
import {SocietyDTO} from "../../context/SocietyProvider";
import SocietyCard from "../societyCard/SocietyCard";
import {useSectionsRefs} from "../mainWrapper/MainWrapper";
import {useNavigate} from "react-router-dom";

interface SocietiesListProps {
    societies: SocietyDTO[]
}

const SocietiesList = ({societies}: SocietiesListProps) => {

    const {societiesSection} = useSectionsRefs();
    const navigate = useNavigate();

    return (
        <Box
            ref={societiesSection}
            sx={{
                backgroundColor: "secondary.main",
                display: "flex",
                justifyContent: "center",
            }}
        >
            <div className={styles.content}>
                <Typography
                    sx={{
                        color: '#ffffff',
                        fontSize: {xs: 30, sm: 40},
                    }}
                    variant={"firstVariant"}
                >
                    SOCIÉTÉS
                </Typography>

                <Typography
                    sx={{
                        color: '#ffffff',
                        fontSize: {xs: 18, sm: 20, md: 24},
                    }}
                    variant={"firstVariant"}
                    textAlign={"left"}
                >
                    Qu’elles vendent des produits artisanaux, offrent leurs services sur rendez-vous, organisent des
                    manifestations,
                    ou veuillent tout simplement se faire connaître, les sociétés de ta région sont sur Looty.
                    N’attends plus pour les découvrir.
                </Typography>

                <Typography
                    sx={{
                        color: '#ffffff',
                        fontSize: {xs: 20, sm: 24, md: 28},
                        mt: 6,
                        mb: 2,
                    }}
                    variant={"firstVariant"}
                    textAlign={"center"}
                >
                    Ils nous ont rejoints récemment
                </Typography>

                <Grid2 container spacing={6}>
                    {societies.map((society) => (
                        <SocietyCard key={society.id} society={society}/>
                    ))}
                </Grid2>

                <Button
                    sx={{
                        mt: 6,
                        fontSize: {xs: 16, sm: 18, md: 20},
                    }}
                    variant="fourthVariant"
                    onClick={() => navigate('/societies')}
                >
                    Voir toutes les sociétés
                </Button>
            </div>
        </Box>
    );
}

export default SocietiesList