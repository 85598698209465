import React from "react";
import Box from "@mui/material/Box";
import {useCart} from "../../../hooks/useCart";
import {Grid2} from "@mui/material";
import CartVerificationItem from "./cartVerificationItem/CartVerificationItem";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import useFormatNumber from "../../../hooks/useFormatNumber";

const CartVerification = () => {

    const {cartItems, getTotalPrice} = useCart();
    const {formatCentsToPrice} = useFormatNumber();

    return (
        <Box
            sx={{
                display: {sm: 'block'},
                mt: {xs: 4, sm: 8},
            }}
        >
            <Grid2 container>
                <Grid2 container size={12}>
                    {cartItems.map((cartItem) => (
                        <Grid2 container size={12} key={cartItem.product.id}>
                            <Grid2 size={12}>
                                <CartVerificationItem product={cartItem.product} quantity={cartItem.quantity}/>
                            </Grid2>
                            <Grid2 size={12}>
                                <Divider sx={{mt: {xs: 4, sm: 4}, mb: 4}}/>
                            </Grid2>
                        </Grid2>
                    ))}
                </Grid2>
                <Grid2 size={12} mb={2} offset={{xs: 3, sm: 0}}>
                    <Typography
                        variant={"firstVariant"}
                        textAlign={{xs: "left", sm: "right"}}
                        pr={2}
                        pl={2}
                        fontSize={{xs: 18, sm: 20, md: 22}}
                    >
                        Total: {formatCentsToPrice(getTotalPrice())}.-
                    </Typography>
                </Grid2>
            </Grid2>
        </Box>
    );
}

export default CartVerification