import React, {useState} from "react";
import styles from "./GlobalSearch.module.css";
import Typography from "@mui/material/Typography";
import AutoComplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import CustomGlobalSearchTextField from "../customMUIComponents/CustomGlobalSearchTextField";
import {SocietyDTO} from "../../context/SocietyProvider";

interface GlobalSearchProps {
    societies: SocietyDTO[],
}

const GlobalSearch = ({societies}: GlobalSearchProps) => {

    const [societySearch, setSocietySearch] = useState<string>('');

    return (
        <Box
            sx={{
                backgroundColor: "primary.main",
                minHeight: "500px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <div className={styles.content}>
                <Box
                    component="img"
                    sx={{width: {xs: 200, sm: 250}}}
                    src={'/looty-logo.png'}
                    alt="looty logo"
                />

                <Typography
                    sx={{
                        color: '#ffffff',
                        fontSize: {xs: 20, sm: 35},
                        mt: 4,
                        mb: 4,
                    }}
                    variant={"firstVariant"}
                >
                    Les commerçants de ta région n'ont jamais été aussi proches.
                </Typography>

                <AutoComplete
                    onChange={(e, value) => {
                        for (const society of societies
                            .filter((society1) => society1.name !== undefined)) {
                            if (society.name!.includes(value)) {
                                window.open(`https://${society.subdomain}.looty.ch`, '_blank');
                                break;
                            }
                        }
                    }}

                    onKeyUp={(event) => {
                        if (societySearch && event.key === 'Enter') {
                            //TODO maybe nothing to do
                        }
                    }}

                    filterOptions={(societiesNames, state) => {
                        return societiesNames
                            .filter((societyName) => societyName !== undefined)
                            .filter((societyName) => {
                                return societyName!.toLowerCase()
                                    .normalize('NFD')
                                    .replace(/\p{Diacritic}/gu, '')
                                    .includes(
                                        state.inputValue.toLowerCase()
                                            .normalize('NFD')
                                            .replace(/\p{Diacritic}/gu, '')
                                    );
                            });
                    }}
                    freeSolo
                    id="free-solo-2-demo"
                    disableClearable
                    options={societySearch ? societies.map((society) => society.name) : []}
                    renderInput={(params) => (
                        <CustomGlobalSearchTextField setField={setSocietySearch} params={params}/>
                    )}
                    slotProps={{
                        paper: {
                            sx: {
                                marginLeft: '15px',
                                marginRight: '15px',
                            }
                        }
                    }}
                />
            </div>
        </Box>
    );
}

export default GlobalSearch