import React from "react";
import Box from "@mui/material/Box";
import {Stack} from "@mui/material";
import CustomTextField from "../../customMUIComponents/CustomTextField";

export interface DeliveryDataProps {
    firstname: string,
    setFirstname: (firstname: string) => void,
    lastname: string,
    setLastname: (firstname: string) => void,
    phoneNumber: string,
    setPhoneNumber: (phoneNumber: string) => void,
    email: string,
    setEmail: (email: string) => void,
    zip: string,
    setZip: (zip: string) => void,
    city: string,
    setCity: (city: string) => void,
    street: string,
    setStreet: (street: string) => void,
    streetNumber: string,
    setStreetNumber: (streetNumber: string) => void,
}

const DeliveryData = ({firstname, setFirstname, lastname, setLastname, phoneNumber, setPhoneNumber, email, setEmail, zip, setZip, city, setCity, street, setStreet, streetNumber, setStreetNumber}: DeliveryDataProps) => {

    return (
        <Box
            sx={{
                display: {sm: 'block'},
                mt: 4,
            }}
        >
            <>
                <Stack spacing={4} direction={{xs: "column", sm: 'column', md: 'row'}} mb={4}>
                    <CustomTextField
                        variant={"outlined"}
                        value={firstname}
                        label="Prénom"
                        setField={setFirstname}
                        required/>
                    <CustomTextField
                        variant={"outlined"}
                        value={lastname}
                        label="Nom"
                        setField={setLastname}
                        required/>
                </Stack>
                <Stack spacing={4} direction={{xs: "column", sm: 'column', md: 'row'}} mb={4}>
                    <CustomTextField
                        variant={"outlined"}
                        value={phoneNumber}
                        label="N° de téléphone"
                        setField={setPhoneNumber}
                        required/>
                    <CustomTextField
                        type={"email"}
                        variant={"outlined"}
                        value={email}
                        label="Adresse email"
                        setField={setEmail}
                        required/>
                </Stack>
                <Stack spacing={4} direction={{xs: "column", sm: 'column', md: 'row'}} mb={4}>
                    <CustomTextField
                        variant={"outlined"}
                        value={zip}
                        label="NPA"
                        setField={setZip}
                        required/>
                    <CustomTextField
                        variant={"outlined"}
                        value={city}
                        label="Localité"
                        setField={setCity}
                        required/>
                </Stack>
                <Stack spacing={4} direction={{xs: "column", sm: 'column', md: 'row'}} mb={4}>
                    <CustomTextField
                        variant={"outlined"}
                        value={street}
                        label="Rue"
                        setField={setStreet}
                        required/>
                    <CustomTextField
                        variant={"outlined"}
                        value={streetNumber}
                        label="N° de rue"
                        setField={setStreetNumber}
                        required/>
                </Stack>
            </>
        </Box>
    );
}

export default DeliveryData