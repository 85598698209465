import React, {useEffect, useState} from "react";
import useSociety from "../../../hooks/useSociety";
import useDataPost from "../../../hooks/useDataPost";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import styles from "./SocietyCart.module.css";
import CheckoutStepper from "../../../components/checkoutStepper/CheckoutStepper";
import {StepDescription} from "../../register/Register";
import CartVerification from "../../../components/checkoutStepper/cartVerification/CartVerification";
import {Alert, Button, Grid2} from "@mui/material";
import DeliveryData from "../../../components/checkoutStepper/deliveryData/DeliveryData";
import {useCart} from "../../../hooks/useCart";
import DatatransLightBox from "./datatransLightBox/DatatransLightBox";
import {useNavigate} from "react-router-dom";
import {useSectionsRefs} from "../../../components/mainWrapper/MainWrapper";

const statusMapping = new Map<string, string>([
    ["success", "Votre commande a été passée avec succès et un mail  de confirmation vous sera envoyé sous peu."],
    ["error", "Une erreur s'est produite et votre paiement n'a pas pu être réalisé. Veuillez réessayer."],
    ["cancelled", "Le processus de paiement a été annulé,"]
]);

export interface DeliveryData {
    firstname: string;
    lastname: string;
    phoneNumber: string;
    email: string;
    zip: string;
    city: string;
    street: string;
    streetNumber: string;
}

export interface DatatransTransactionResponseDTO {
    transactionId?: string;
}

const SocietyCart = () => {
    const {society} = useSociety();
    const {cartItems, getTotalPrice, clearCart} = useCart();

    const [activeStep, setActiveStep] = useState<number>(0);
    const [error, setError] = useState<string>('');
    const [initTransactionError, setInitTransactionError] = useState<any>(null);

    const [transactionId, setTransactionId] = useState<string>('');
    const [paymentStatus, setPaymentStatus] = useState<string>('');

    const [disableNextButton, setDisableNextButton] = useState<boolean>(false);


    const navigate = useNavigate();

    const {
        data: datatransTransactionResponse,
        postDataPromise,
    } = useDataPost<DatatransTransactionResponseDTO>(`/datatrans/init-transaction`, {}, false, false);

    const handleNext = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setDisableNextButton(true);

        const currentStepDescriptionDataValidation = stepsDescriptions[activeStep].stepDescriptionDataValidation;

        let isDataValid = true;
        for (const dataValidation of currentStepDescriptionDataValidation) {
            if (!dataValidation.validationMethod()) {
                isDataValid = false;
                break;
            }
        }

        if (!isDataValid) {
            return;
        }

        if (activeStep < stepsDescriptions.length - 1) {
            setActiveStep(activeStep + 1);
            setDisableNextButton(false);
        }

        if (activeStep === stepsDescriptions.length - 1) {
            initTransaction();
        }
    }

    const handleBack = () => {
        setError('');
        setInitTransactionError(null);
        if (activeStep > 0) {
            setActiveStep(activeStep - 1);
        }
    }

    const initTransaction = async () => {
        console.log('XXXXX');

        const formData: DeliveryData = {
            firstname: firstnameDeliveryData,
            lastname: lastnameDeliveryData,
            street: streetDeliveryData,
            streetNumber: streetNumberDeliveryData,
            city: cityDeliveryData,
            zip: zipDeliveryData,
            email: emailDeliveryData,
            phoneNumber: phoneNumberDeliveryData,
        }

        localStorage.setItem(`deliveryData_${society.name}`, JSON.stringify(formData));

        const basketData: any[] = []
        cartItems.forEach((cartItem) => {
            basketData.push({
                productId: cartItem.product.id,
                quantity: cartItem.quantity,
            })
        })

        postDataPromise(undefined, {
            societyId: society.id,
            basket: basketData,
            totalPrice: getTotalPrice(),
            ...formData
        }).then((response) => {
            setTransactionId(response.data.transactionId!);
        }).catch((error) => {
            setInitTransactionError(error);
        }).finally(() => {
        })
    }

    const backToCart = () => {
        navigate('/cart');
        setPaymentStatus('');
    }

    // Delivery Data
    const [firstnameDeliveryData, setFirstnameDeliveryData] = useState<string>('');
    const [lastnameDeliveryData, setLastnameDeliveryData] = useState<string>('');
    const [phoneNumberDeliveryData, setPhoneNumberDeliveryData] = useState<string>('');
    const [emailDeliveryData, setEmailDeliveryData] = useState<string>('');
    const [zipDeliveryData, setZipDeliveryData] = useState<string>('');
    const [cityDeliveryData, setCityDeliveryData] = useState<string>('');
    const [streetDeliveryData, setStreetDeliveryData] = useState<string>('');
    const [streetNumberDeliveryData, setStreetNumberDeliveryData] = useState<string>('');

    const stepsDescriptions: StepDescription[] = [
        {
            stepTitle: "Vérification du panier",
            stepNumber: 0,
            stepComponent: (
                <CartVerification/>
            ),
            stepDescriptionDataValidation: [],
        },
        {
            stepTitle: "Données de livraison",
            stepNumber: 1,
            stepComponent: (
                <DeliveryData
                    firstname={firstnameDeliveryData}
                    setFirstname={setFirstnameDeliveryData}
                    lastname={lastnameDeliveryData}
                    setLastname={setLastnameDeliveryData}
                    phoneNumber={phoneNumberDeliveryData}
                    setPhoneNumber={setPhoneNumberDeliveryData}
                    email={emailDeliveryData}
                    setEmail={setEmailDeliveryData}
                    zip={zipDeliveryData}
                    setZip={setZipDeliveryData}
                    city={cityDeliveryData}
                    setCity={setCityDeliveryData}
                    street={streetDeliveryData}
                    setStreet={setStreetDeliveryData}
                    streetNumber={streetNumberDeliveryData}
                    setStreetNumber={setStreetNumberDeliveryData}
                />

            ),
            stepDescriptionDataValidation: [],
        }
    ]

    const {
        setNavItems,
        setDisplayLoginButton,
        setDisplayRegisterSocietyButton,
        setDisplayCart,
        setDisplayMobileMenu
    } = useSectionsRefs();

    const loadLocalStorage = () => {
        const storedDeliveryData = localStorage.getItem(`deliveryData_${society.name}`);
        if (storedDeliveryData) {
            const parsedStoredDeliveryData: DeliveryData = JSON.parse(storedDeliveryData);
            setFirstnameDeliveryData(parsedStoredDeliveryData.firstname);
            setLastnameDeliveryData(parsedStoredDeliveryData.lastname);
            setEmailDeliveryData(parsedStoredDeliveryData.email);
            setPhoneNumberDeliveryData(parsedStoredDeliveryData.phoneNumber);
            setZipDeliveryData(parsedStoredDeliveryData.zip);
            setCityDeliveryData(parsedStoredDeliveryData.city);
            setStreetDeliveryData(parsedStoredDeliveryData.street);
            setStreetNumberDeliveryData(parsedStoredDeliveryData.streetNumber);
        }
    }

    useEffect(() => {
        setNavItems([]);
        setDisplayRegisterSocietyButton(false);
        setDisplayCart(false);
        setDisplayLoginButton(false);
        setDisplayMobileMenu(false);

        datatransTransactionResponse?.transactionId && setTransactionId(datatransTransactionResponse.transactionId)

        const params = new URLSearchParams(window.location.search);
        const status = params.get('status');
        if (status && status !== paymentStatus) {
            setPaymentStatus(status || '');
        }

        if (status === 'success') {
            clearCart();
        } else {
            loadLocalStorage();
        }

    }, [datatransTransactionResponse, society, paymentStatus]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [activeStep]);

    return (
        <>
            <Box sx={{
                width: "100%",
                backgroundColor: 'primary.main',
                alignContent: 'center'
            }}>

                <Box
                    sx={{
                        backgroundColor: "primary.main",
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <div className={styles.content}>
                        <div className={styles.realContent}>
                            {paymentStatus ? (
                                <>
                                    <Typography
                                        fontSize={{xs: 18, sm: 20, md: 22}}
                                        variant={"secondVariant"}
                                    >
                                        Statut du paiement: {paymentStatus}
                                    </Typography>
                                    <Typography
                                        fontSize={{xs: 16}}
                                        variant={"secondVariant"}
                                    >
                                        {statusMapping.get(paymentStatus)}
                                    </Typography>
                                    <Grid2 container>
                                        {paymentStatus !== 'success' && (
                                            <Grid2 size={12}>
                                                <Button
                                                    variant={"firstVariant"}
                                                    sx={{
                                                        mt: 2,
                                                    }}
                                                    onClick={() => backToCart()}
                                                >
                                                    Retour au panier
                                                </Button>
                                            </Grid2>
                                        )}
                                        <Grid2 size={12}>
                                            <Button
                                                variant={"firstVariant"}
                                                sx={{
                                                    mt: 2,
                                                    mb: 2,
                                                }}
                                                onClick={() => navigate('/')}
                                            >
                                                Retourner sur la page du commerçant
                                            </Button>
                                        </Grid2>

                                    </Grid2>
                                </>
                            ) : (
                                <>
                                    <CheckoutStepper activeStep={activeStep} stepsDescriptions={stepsDescriptions}/>
                                    <Box
                                        sx={{
                                            pl: "10px",
                                            pr: "10px",
                                            pb: "10px",
                                        }}
                                    >
                                        <form onSubmit={(e) => handleNext(e)}>
                                            <Typography
                                                variant={"secondVariant"}
                                                sx={{
                                                    fontSize: {xs: 22},
                                                    display: {xs: 'block', sm: 'none'},
                                                }}
                                            >
                                                {stepsDescriptions[activeStep].stepTitle}
                                            </Typography>

                                            <Box>
                                                {stepsDescriptions[activeStep].stepComponent}
                                            </Box>

                                            {error && (
                                                <Alert
                                                    severity="error"
                                                    sx={{mb: 4}}
                                                >
                                                    {error}
                                                </Alert>
                                            )}

                                            {initTransactionError && (
                                                <Alert
                                                    severity="error"
                                                    sx={{mb: 4}}
                                                >
                                                    {"Une erreur a eu lieu et le processus de paiement n'a pas pu être démarré. Veuillez vérifier à nouveau votre panier."}
                                                </Alert>
                                            )}

                                            <Grid2 container>
                                                <Grid2 size={6} textAlign={"left"}>
                                                    <Button
                                                        sx={{
                                                            display: activeStep > 0 ? 'block' : 'none'
                                                        }}
                                                        onClick={() => handleBack()}
                                                        variant={"fourthVariant"}
                                                    >
                                                        Précédent
                                                    </Button>
                                                </Grid2>
                                                <Grid2 size={6} textAlign={"right"}>
                                                    <Button
                                                        type={"submit"}
                                                        variant={"fourthVariant"}
                                                        disabled={disableNextButton}
                                                    >
                                                        {activeStep === stepsDescriptions.length - 1 ? 'Payer' : 'Suivant'}
                                                    </Button>
                                                </Grid2>
                                            </Grid2>
                                        </form>
                                    </Box>
                                </>
                            )}
                        </div>
                    </div>
                </Box>
            </Box>
            {transactionId && <DatatransLightBox transactionId={transactionId} setTransactionId={setTransactionId} setDisableNextButton={setDisableNextButton}/>}
        </>
    )
}

export default SocietyCart