import React, {useEffect} from "react";
import styles from "./Societies.module.css"
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {SocietyDTO} from "../../context/SocietyProvider";
import {useSectionsRefs} from "../../components/mainWrapper/MainWrapper";
import useDataFetch from "../../hooks/useDataFetch";
import {Grid2} from "@mui/material";
import SocietyCard from "../../components/societyCard/SocietyCard";
import {AdvancedMarker, Map} from "@vis.gl/react-google-maps";

const Societies = () => {

    const {setNavItems, setDisplayRegisterSocietyButton} = useSectionsRefs();

    const {data: societies} = useDataFetch<SocietyDTO>(
        `/societies`, false
    );

    useEffect(() => {
        setDisplayRegisterSocietyButton(false);
    }, [setDisplayRegisterSocietyButton, setNavItems]);

    return (
        <Box sx={{
            width: "100%",
            backgroundColor: 'secondary.main',
            alignContent: 'center'
        }}>

            <Box
                sx={{
                    backgroundColor: "secondary.main",
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                <div className={styles.content}>
                    <div className={styles.realContent}>
                        <Typography
                            sx={{
                                color: '#ffffff',
                                fontSize: {xs: 30, sm: 40},
                                mb: 4,
                            }}
                            variant={"firstVariant"}
                        >
                            Sociétés
                        </Typography>

                        {societies && societies.length > 0 && (
                            <Map
                                mapId={"97ef1108e25bae36"}
                                style={{width: '100%', height: '50vh'}}
                                defaultCenter={{
                                    lat: societies[0].societyContactDetails.lat,
                                    lng: societies[0].societyContactDetails.lng
                                }}
                                defaultZoom={15}
                                gestureHandling={'cooperative'}
                                disableDefaultUI={false}
                            >
                                {societies.map((society: SocietyDTO) => (
                                    <AdvancedMarker
                                        key={society.id}
                                        onClick={() => alert('work in progress')}
                                        position={{
                                            lat: society.societyContactDetails!.lat,
                                            lng: society.societyContactDetails!.lng
                                        }}
                                    >
                                    </AdvancedMarker>
                                ))}

                            </Map>
                        )}

                        <Grid2 container spacing={6} mt={4}>
                            {societies && societies.length > 0 && societies.map((mappedSociety: SocietyDTO) => (
                                <SocietyCard key={mappedSociety.id} society={mappedSociety}/>
                            ))}
                        </Grid2>

                    </div>
                </div>
            </Box>
        </Box>
    );
}

export default Societies