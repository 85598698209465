import React, {useEffect} from "react";
import useSociety from "../../../hooks/useSociety";
import SocietyHomepageContact from "../../../components/societyHomepage/societyHomepageContact/SocietyHomepageContact";
import SocietyHomepageArticles
    from "../../../components/societyHomepage/societyHomepageArticles/SocietyHomepageArticles";
import styles from "./SocietyHomepage.module.css";
import SocietyHomepageDescription
    from "../../../components/societyHomepage/societyHomepageDescription/SocietyHomepageDescription";
import {useSectionsRefs} from "../../../components/mainWrapper/MainWrapper";
import CartOverlay from "../../../components/cartOverlay/CartOverlay";
import {useCartOverlay} from "../../../hooks/useCart";

const SocietyHomepage = () => {

    const {society} = useSociety();

    const {
        societyDescriptionSection,
        articlesSection,
        contactSection,
        setNavItems,
        setDisplayRegisterSocietyButton,
        setDisplayCart
    } = useSectionsRefs();

    const {isCartOpen, closeCart} = useCartOverlay();

    useEffect(() => {
        const navItems = [
            {title: 'Accueil', scrollRef: societyDescriptionSection},
            {title: 'Articles', scrollRef: articlesSection},
            {title: 'Contact', scrollRef: contactSection},
        ];
        setNavItems(navItems)
        setDisplayRegisterSocietyButton(false);
        setDisplayCart(true);
    }, [societyDescriptionSection, articlesSection, contactSection, setDisplayRegisterSocietyButton, setNavItems]);

    return (
        <>
            <div className={styles.container}>
                {(society && society.enabled && society.visible) ? (
                    <>
                        <SocietyHomepageDescription/>
                        {/*{society.societyAdminDetails?.selling && <SocietyHomepageArticles/>}*/}
                        <SocietyHomepageArticles/>
                        <SocietyHomepageContact/>
                    </>
                ) : (
                    <div>
                        <p>Cette société n'est pas accessible actuellement.</p>
                        <p>Si vous en êtes le propriétéaire, veuillez vous connecter pour la gérer.</p>
                    </div>
                )}
            </div>
            <CartOverlay isCartOpen={isCartOpen} closeCart={closeCart}/>
        </>
    );
}

export default SocietyHomepage