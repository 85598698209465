import {useEffect, useState} from "react";
import axios from '../api/axios';
import useAxiosPrivate from "./useAxiosPrivate";

const useDataDelete = (endpoint: string, isPrivate: boolean, fireAutomatically: boolean = true) => {
    const [error, setError] = useState<any>(null);
    const [isLoading, setLoading] = useState(false);

    const axiosPrivate = useAxiosPrivate();
    const customAxios = isPrivate ? axiosPrivate : axios;

    const deleteData = async (endpointOverride: string | undefined = undefined) => {
        setLoading(true);
        const endpointToUse: string = endpointOverride ? endpointOverride : endpoint;
        try {
            await customAxios.delete(endpointToUse);
        } catch (error) {
            setError(error)
        } finally {
            setLoading(false);
        }
    }

    const deleteDataPromise = async (endpointOverride: string | undefined = undefined) => {
        const endpointToUse: string = endpointOverride ? endpointOverride : endpoint;
        return await customAxios.delete(endpointToUse);
    }

    useEffect(() => {
        if (fireAutomatically) {
            deleteData();
        }
    }, [fireAutomatically, isPrivate, endpoint]);

    return {error, isLoading, deleteData, deleteDataPromise};

}

export default useDataDelete;