import React, {ReactNode, useEffect, useState} from "react";
import ShoppingCartContext, {CartItem, ShoppingCartContextType} from "./ShoppingCartContext";
import {
    ProductDTO
} from "../components/societyAdmin/societyAdminArticles/societyAdminArticlesList/SocietyAdminArticlesList";
import CartOverlayContext from "./CartOverlayContext";
import useSociety from "../hooks/useSociety";
import useDataFetch from "../hooks/useDataFetch";

interface ShoppingCartProviderProps {
    children: ReactNode
}

const ShoppingCartProvider = ({children}: ShoppingCartProviderProps) => {
    const [cartItems, setCartItems] = useState<CartItem[]>([]);
    const [cartLoaded, setCartLoaded] = useState(false);
    const [isCartOpen, setIsCartOpen] = useState(false);

    const {society} = useSociety();

    const {fetchDataPromise} = useDataFetch<ProductDTO[]>('', false, false);

    // load and set local storage
    useEffect(() => {
        if (society.name) {
            const storedCartItems = localStorage.getItem(`cart_${society.name}`);
            if (storedCartItems) {
                const parsedCartItems: CartItem[] = JSON.parse(storedCartItems);
                let queryParameter = parsedCartItems.map((cartItem: CartItem) => cartItem.product.id).join('&products=');
                queryParameter = '?products=' + queryParameter;

                const newCartItems: CartItem[] = [];
                fetchDataPromise(`/societies/${society.id}/products${queryParameter}`)
                    .then((response) => {
                        const availableIds: number[] = response.data.map((product: ProductDTO) => product.id);
                        parsedCartItems.forEach((cartItem: CartItem) => {
                            if (availableIds.includes(cartItem.product.id)) {
                                newCartItems.push(cartItem);
                            }
                        })
                        localStorage.setItem(`cart_${society.name}`, JSON.stringify(newCartItems));
                        setCartItems(newCartItems);
                    }).catch((error) => {
                    localStorage.setItem(`cart_${society.name}`, JSON.stringify([]));
                    setCartItems([])
                })
            }
        }
    }, [society.name]);

    // Update local storage when cart items change
    useEffect(() => {
        if (society.name) {
            localStorage.setItem(`cart_${society.name}`, JSON.stringify(cartItems));
        }
    }, [cartItems, society.name]);

    // Cart management functions

    const addToCart = (
        product: ProductDTO,
        quantity: number,
    ) => {

        const storedCartItems = localStorage.getItem(`cart_${society.name}`);
        if (storedCartItems) {
            setCartItems(JSON.parse(storedCartItems));
        }

        setCartItems((prevCartItems) => {
            const existingItem = prevCartItems.find(
                (item) => {
                    return item.product.id === product.id;
                },
            );

            if (existingItem) {
                return prevCartItems.map((item) =>
                    item.product.id === product.id
                        ? {...item, quantity: item.quantity + quantity}
                        : item,
                );
            } else {
                return [...prevCartItems, {product, quantity}];
            }
        });
    };

    const removeFromCart = (productId: number) => {
        const storedCartItems = localStorage.getItem(`cart_${society.name}`);
        if (storedCartItems) {
            setCartItems(JSON.parse(storedCartItems));
        }

        setCartItems(
            cartItems.filter(
                (item) =>
                    item.product.id !== productId,
            ),
        );
    };

    const updateCartItemQuantity = (
        productId: number,
        quantity: number,
    ) => {
        const storedCartItems = localStorage.getItem(`cart_${society.name}`);
        if (storedCartItems) {
            setCartItems(JSON.parse(storedCartItems));
        }

        const existingCartItemIndex = cartItems.findIndex(
            (item) => item.product.id === productId,
        );

        if (existingCartItemIndex >= 0) {
            const updatedCartItems = [...cartItems];
            updatedCartItems[existingCartItemIndex].quantity = quantity;
            setCartItems(updatedCartItems);
        }
    };

    const clearCart = () => {
        setCartItems([]);
    };

    const openCart = () => {
        setIsCartOpen(true);
    };

    const closeCart = () => {
        setIsCartOpen(false);
    };

    const getItemsNumber = (): number => {
        const itemsNumber = 0;
        return cartItems.reduce(
            (accumulator, currentValue) => accumulator + currentValue.quantity, itemsNumber
        );
    }

    const isAlreadyAdded = (productId: number): boolean => {
        return cartItems.map((cartItem) => cartItem.product.id).includes(productId);
    }

    const getTotalPrice = (): number => {
        const itemsNumber = 0;
        return cartItems.reduce(
            (accumulator, currentValue) => accumulator + currentValue.quantity * currentValue.product.price, itemsNumber
        );
    }

    const getProductQuantity = (productId: number): number => {
        const cartItem = cartItems.find((cartItem: CartItem) => cartItem.product.id === productId);
        return cartItem ? cartItem.quantity : 0;
    }

    const cartOverlayContextValue = {
        isCartOpen,
        openCart,
        closeCart,
    };

    const shoppingCartContextValue: ShoppingCartContextType = {
        cartItems,
        addToCart,
        removeFromCart,
        updateCartItemQuantity,
        clearCart,
        getItemsNumber,
        isAlreadyAdded,
        getTotalPrice,
        getProductQuantity,
    };

    return (
        <ShoppingCartContext.Provider value={shoppingCartContextValue}>
            <CartOverlayContext.Provider value={cartOverlayContextValue}>
                {children}
            </CartOverlayContext.Provider>
        </ShoppingCartContext.Provider>
    );
};

export default ShoppingCartProvider;