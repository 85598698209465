import React from "react";
import {SocietyDTO} from "../../context/SocietyProvider";
import {Card, CardActionArea, CardContent, Grid2} from "@mui/material";
import Box from "@mui/material/Box";
import styles from "../societiesList/SocietiesList.module.css";
import Typography from "@mui/material/Typography";

interface SocietyCardProps {
    society: SocietyDTO
}

const SocietyCard = ({society}: SocietyCardProps) => {

    const [imgError, setImgError] = React.useState(false);

    return (
        <Grid2 key={society.name! + society.id} size={{xs: 12, sm: 6, md: 6, lg: 3}}>
            <div
                onClick={() => window.open(`https://${society.subdomain}.looty.ch`, '_blank')}
            >
                <Card
                    sx={{
                        borderRadius: 2,
                        maxWidth: {xs: '300px', sm: '450px'},
                        margin: 'auto'
                    }}
                >
                    <CardActionArea>
                        <CardContent>
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems={"center"}
                                overflow="hidden"
                                sx={{
                                    width: '100%',
                                    height: '250px',
                                    alignItems: 'center',
                                    alignContent: 'center',
                                    padding: 1,
                                    backgroundColor: '#ffffff',
                                }}
                            >
                                <img
                                    className={styles.societyImage}
                                    src={imgError ? 'missing-image.png' : `https://looty.ch:8443/api/v1/societies/${society.id}/image`}
                                    onError={({currentTarget}) => {
                                        setImgError(true);
                                    }}
                                    alt="society"/>
                            </Box>
                            <Typography
                                textAlign='left'
                                sx={{
                                    color: 'black',
                                    fontSize: '18px'
                                }}
                            >
                                {society.name}
                            </Typography>
                            <Box
                                min-width={0}
                                height='100px'
                            >
                                <Typography
                                    textAlign='justify'
                                    sx={{
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        display: '-webkit-box',
                                        WebkitLineClamp: '4',
                                        WebkitBoxOrient: 'vertical',
                                        color: 'black',
                                    }}
                                >
                                    {society.description}
                                </Typography>
                            </Box>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </div>
        </Grid2>
    );
}

export default SocietyCard