import { createContext } from "react";
import {
    ProductDTO
} from "../components/societyAdmin/societyAdminArticles/societyAdminArticlesList/SocietyAdminArticlesList";

export type CartItem = {
    product: ProductDTO;
    quantity: number;
};

export type ShoppingCartContextType = {
    cartItems: CartItem[];
    addToCart: (
        product: ProductDTO,
        quantity: number,
    ) => void;
    removeFromCart: (productId: number) => void;
    updateCartItemQuantity: (
        productId: number,
        quantity: number,
    ) => void;
    clearCart: () => void;
    getItemsNumber: () => number;
    isAlreadyAdded: (productId: number) => boolean
    getTotalPrice: () => number;
    getProductQuantity: (productId: number) => number;
};

const ShoppingCartContext = createContext<ShoppingCartContextType>({
    cartItems: [],
    addToCart: () => {},
    removeFromCart: () => {},
    updateCartItemQuantity: () => {},
    clearCart: () => {},
    getItemsNumber: () => 0,
    isAlreadyAdded: () => false,
    getTotalPrice: () => 0,
    getProductQuantity: () => 0,
});

export default ShoppingCartContext;