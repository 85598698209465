import React, {ReactElement, useEffect, useState} from "react";
import {useSectionsRefs} from "../../components/mainWrapper/MainWrapper";
import styles from "./Register.module.css";
import {Alert, Button, Grid2} from "@mui/material";
import Box from "@mui/material/Box";
import RegisterStepper from "../../components/registerStepper/RegisterStepper";
import SubscriptionSelection from "../../components/registerStepper/subscriptionSelection/SubscriptionSelection";
import SocietyData from "../../components/registerStepper/societyData/SocietyData";
import Typography from "@mui/material/Typography";
import PersonnalData from "../../components/registerStepper/personnalData/PersonnalData";
import BankData from "../../components/registerStepper/bankData/BankData";
import OtherInformationData from "../../components/registerStepper/otherInformation/OtherInformationData";
import useDataPost from "../../hooks/useDataPost";
import RegistrationConfirmationStep
    from "../../components/registerStepper/registrationConfirmationStep/RegistrationConfirmationStep";
import useDataFetch from "../../hooks/useDataFetch";
import {SocietyDTO} from "../../context/SocietyProvider";

interface StepDescriptionDataValidation {
    validationMethod: () => Promise<boolean>,
}

interface SuccessfullRegisterResponseDTO {
    email: string,
}

interface ValidateMailNotInUseResponseDTO {
    mailAlreadyInUse: boolean;
}

export interface StepDescription {
    stepTitle: string,
    stepNumber: number,
    stepComponent: ReactElement,
    isOptional?: boolean,
    stepDescriptionDataValidation: StepDescriptionDataValidation[],
}

const Register = () => {

    const {
        isLoading,
        data,
        postData
    } = useDataPost<SuccessfullRegisterResponseDTO>(`/auth/register`, {}, false, false, false);

    const {data: validateMailNotInUseResponse, postDataPromise: postDataValidateMail}
        = useDataPost<ValidateMailNotInUseResponseDTO>(`/auth/validate-mail-not-in-use`, {}, false, false, false);

    const {data: societies} = useDataFetch<SocietyDTO>(
        `/societies`, false
    );

    const [activeStep, setActiveStep] = useState<number>(0);

    const [error, setError] = useState<string>('');

    // SubscriptionSelect
    const [subscriptionChoiceSubscriptionSelect, setSubscriptionChoiceSubscriptionSelect] = useState<string>('');

    // SocietyData
    const [societyNameSocietyData, setSocietyNameSocietyData] = useState<string>('');
    const [activitySectorSocietyData, setActivitySectorSocietyData] = useState<string>('');
    const [zipSocietyData, setZipSocietyData] = useState<string>('');
    const [citySocietyData, setCitySocietyData] = useState<string>('');
    const [streetSocietyData, setStreetSocietyData] = useState<string>('');
    const [streetNumberSocietyData, setStreetNumberSocietyData] = useState<string>('');

    // PersonnalData
    const [firstnamePersonnalData, setFirstnamePersonnalData] = useState<string>('');
    const [lastnamePersonnalData, setLastnamePersonnalData] = useState<string>('');
    const [phoneNumberPersonnalData, setPhoneNumberPersonnalData] = useState<string>('');
    const [emailPersonnalData, setEmailPersonnalData] = useState<string>('');
    const [emailConfirmationPersonnalData, setEmailConfirmationPersonnalData] = useState<string>('');
    const [passwordPersonnalData, setPasswordPersonnalData] = useState<string>('');
    const [passwordConfirmationPersonnalData, setPasswordConfirmationPersonnalData] = useState<string>('');

    // BankData
    const [isSellingBankData, setIsSellingBankData] = useState<boolean | undefined>(undefined);
    const [ibanBankData, setIbanBankData] = useState<string>('');
    const [bankNameBankData, setBankNameBankData] = useState<string>('');

    // OtherInformationData
    const [subdomainOtherInformationData, setSubdomainOtherInformationData] = useState<string>('');
    const [societyDescriptionInformationData, setSocietyDescriptionInformationData] = useState<string>('');

    const [isNextButtonDisabled, setIsNextButtonDisabled] = useState<boolean>(false);

    const handleSetSubdomainOtherInformationData = (subdomain: string) => {
        setSubdomainOtherInformationData(subdomain ? subdomain.toLowerCase() : subdomain);
    }

    const stepsDescriptions: StepDescription[] = [
        {
            stepTitle: "Sélection de l'abonnement",
            stepNumber: 0,
            stepComponent: (
                <SubscriptionSelection
                    subscriptionChoice={subscriptionChoiceSubscriptionSelect}
                    setSubscriptionChoice={setSubscriptionChoiceSubscriptionSelect}
                />
            ),
            stepDescriptionDataValidation: [],
        },
        {
            stepTitle: "Données de la société",
            stepNumber: 1,
            stepComponent: (
                <SocietyData
                    societyName={societyNameSocietyData}
                    setSocietyName={setSocietyNameSocietyData}
                    activitySector={activitySectorSocietyData}
                    setActivitySector={setActivitySectorSocietyData}
                    zip={zipSocietyData}
                    setZip={setZipSocietyData}
                    city={citySocietyData}
                    setCity={setCitySocietyData}
                    street={streetSocietyData}
                    setStreet={setStreetSocietyData}
                    streetNumber={streetNumberSocietyData}
                    setStreetNumber={setStreetNumberSocietyData}
                />
            ),
            stepDescriptionDataValidation: [
                {
                    validationMethod: () => {
                        if (societies.map((society: SocietyDTO) => society.name?.toLowerCase()).includes(societyNameSocietyData.toLowerCase())) {
                            setError("Ce nom de société est déjà utilisé.")
                            return Promise.resolve(false);
                        }
                        setError('');
                        return Promise.resolve(true);
                    }
                },
            ],
        },
        {
            stepTitle: "Données personnelles",
            stepNumber: 2,
            stepComponent: (
                <PersonnalData
                    firstname={firstnamePersonnalData}
                    setFirstname={setFirstnamePersonnalData}
                    lastname={lastnamePersonnalData}
                    setLastname={setLastnamePersonnalData}
                    phoneNumber={phoneNumberPersonnalData}
                    setPhoneNumber={setPhoneNumberPersonnalData}
                    email={emailPersonnalData}
                    setEmail={setEmailPersonnalData}
                    emailConfirmation={emailConfirmationPersonnalData}
                    setEmailConfirmation={setEmailConfirmationPersonnalData}
                    password={passwordPersonnalData}
                    setPassword={setPasswordPersonnalData}
                    passwordConfirmation={passwordConfirmationPersonnalData}
                    setPasswordConfirmation={setPasswordConfirmationPersonnalData}
                />
            ),
            stepDescriptionDataValidation: [
                {
                    validationMethod: () => {
                        if (emailPersonnalData !== emailConfirmationPersonnalData) {
                            setError("Les adresses email ne correspondent pas.")
                            return Promise.resolve(false);
                        }
                        setError('');
                        return Promise.resolve(true);
                    }
                },
                {
                    validationMethod: () => {
                        if (passwordPersonnalData.length < 8) {
                            setError("Le mot de passe doit faire au moins 8 caractères.")
                            return Promise.resolve(false);
                        }
                        setError('');
                        return Promise.resolve(true);
                    }
                },
                {
                    validationMethod: () => {
                        if (passwordPersonnalData !== passwordConfirmationPersonnalData) {
                            setError("Les mots de passe ne correspondent pas.")
                            return Promise.resolve(false);
                        }
                        setError('');
                        return Promise.resolve(true);
                    }
                },
                {
                    validationMethod: async () => {
                        const response = await postDataValidateMail(undefined, {
                            email: emailPersonnalData,
                        });

                        if (response.data.mailAlreadyInUse) {
                            setError("Cette adresse email est déjà utilisée.");
                            return false;
                        }
                        setError('');
                        return true;
                    }
                },
            ]
        },
        {
            stepTitle: "Coordonnées bancaires",
            stepNumber: 3,
            stepComponent: (
                <BankData
                    isSelling={isSellingBankData}
                    setIsSelling={setIsSellingBankData}
                    iban={ibanBankData}
                    setIban={setIbanBankData}
                    bankName={bankNameBankData}
                    setBankName={setBankNameBankData}
                />
            ),
            isOptional: true,
            stepDescriptionDataValidation: [],
        },
        {
            stepTitle: "Autres informations",
            stepNumber: 4,
            stepComponent: (
                <OtherInformationData
                    subdomain={subdomainOtherInformationData}
                    setSubdomain={handleSetSubdomainOtherInformationData}
                    societyDescription={societyDescriptionInformationData}
                    setSocietyDescription={setSocietyDescriptionInformationData}
                />
            ),
            stepDescriptionDataValidation: [
                {
                    validationMethod: () => {
                        if (societies.map((society: SocietyDTO) => society.subdomain?.toLowerCase()).includes(subdomainOtherInformationData.toLowerCase())) {
                            setError("Ce nom de domaine est déjà utilisé.")
                            return Promise.resolve(false);
                        }
                        setError('');
                        return Promise.resolve(true);
                    }
                },
            ],
        },
    ]

    const {
        setNavItems,
        setDisplayRegisterSocietyButton,
        setDisplayLoginButton,
        setDisplayMobileMenu
    } = useSectionsRefs();

    useEffect(() => {
        setNavItems([]);
        setDisplayRegisterSocietyButton(false);
        setDisplayLoginButton(false);
        setDisplayMobileMenu(false);
        window.scrollTo(0, 0);
    }, [activeStep, setDisplayLoginButton, setNavItems, setDisplayRegisterSocietyButton, setDisplayMobileMenu]);

    const handleNext = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const currentStepDescriptionDataValidation = stepsDescriptions[activeStep].stepDescriptionDataValidation;

        let isDataValid = true;
        for (const dataValidation of currentStepDescriptionDataValidation) {
            const isValid = await dataValidation.validationMethod();
            if (!isValid) {
                isDataValid = false;
                break;
            }
        }

        if (!isDataValid) {
            return;
        }

        if (activeStep < stepsDescriptions.length - 1) {
            setActiveStep(activeStep + 1);
        }

        if (activeStep === stepsDescriptions.length - 1) {
            setIsNextButtonDisabled(true);

            const data = {
                subscriptionChoice: subscriptionChoiceSubscriptionSelect,
                societyName: societyNameSocietyData,
                activitySector: activitySectorSocietyData,
                zip: zipSocietyData,
                city: citySocietyData,
                street: streetSocietyData,
                streetNumber: streetNumberSocietyData,
                firstname: firstnamePersonnalData,
                lastname: lastnamePersonnalData,
                phoneNumber: phoneNumberPersonnalData,
                email: emailPersonnalData,
                password: passwordPersonnalData,
                selling: isSellingBankData,
                iban: ibanBankData,
                bankName: bankNameBankData,
                societySubdomain: subdomainOtherInformationData,
                societyDescription: societyDescriptionInformationData,
            }

            postData(undefined, data);
        }
    }

    const handleBack = () => {
        setError('');
        if (activeStep > 0) {
            setActiveStep(activeStep - 1);
        }
    }

    return (
        <Box sx={{
            width: "100%",
            backgroundColor: 'primary.main',
            alignContent: 'center'
        }}>

            <Box
                sx={{
                    backgroundColor: "primary.main",
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                <div className={styles.content}>
                    <div className={styles.realContent}>
                        {!data ? (
                            <>
                                <RegisterStepper activeStep={activeStep} stepsDescriptions={stepsDescriptions}/>
                                <Box
                                    sx={{
                                        pl: "10px",
                                        pr: "10px",
                                        pb: "10px",
                                    }}
                                >
                                    <form onSubmit={(e) => handleNext(e)}>
                                        <Typography
                                            variant={"secondVariant"}
                                            sx={{
                                                fontSize: {xs: 22},
                                                display: {xs: 'block', sm: 'none'},
                                                mb: 3,
                                            }}
                                        >
                                            {stepsDescriptions[activeStep].stepTitle}
                                        </Typography>

                                        {stepsDescriptions[activeStep].stepComponent}

                                        {error && (
                                            <Alert
                                                severity="error"
                                                sx={{mb: 4}}
                                            >
                                                {error}
                                            </Alert>
                                        )}

                                        <Grid2 container>
                                            <Grid2 size={6} textAlign={"left"}>
                                                <Button
                                                    sx={{
                                                        display: activeStep > 0 ? 'block' : 'none'
                                                    }}
                                                    onClick={() => handleBack()}
                                                    variant={"fourthVariant"}
                                                >
                                                    Précédent
                                                </Button>
                                            </Grid2>
                                            <Grid2 size={6} textAlign={"right"}>
                                                <Button
                                                    type={"submit"}
                                                    variant={"fourthVariant"}
                                                    disabled={isLoading}
                                                >
                                                    {activeStep === stepsDescriptions.length - 1 ? 'Valider' : 'Suivant'}
                                                </Button>
                                            </Grid2>
                                        </Grid2>
                                    </form>
                                </Box>
                            </>
                        ) : (
                            <RegistrationConfirmationStep email={data.email}/>
                        )}

                    </div>
                </div>
            </Box>
        </Box>

    );
}

export default Register