import {useEffect, useState} from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import axios from "../api/axios";

const useDataPut = <T>(endpoint: string = '', body: any = undefined, isPrivate: boolean = false, fireAutomatically: boolean = false, isFormData: boolean = false) => {
    const [data, setData] = useState<null | T>(null);
    const [error, setError] = useState<any>(null);
    const [isLoading, setLoading] = useState(false);

    const axiosPrivate = useAxiosPrivate();
    const customAxios = isPrivate ? axiosPrivate : axios;

    const putData = async (endpointOverride: string | undefined = undefined, bodyOverride: any) => {
        setLoading(true);
        const endpointToUse: string = endpointOverride ? endpointOverride : endpoint;
        const bodyToUse: any = bodyOverride ? bodyOverride : body;
        try {
            const response = await customAxios.put<T>(
                endpointToUse,
                bodyToUse,
                {headers: {'Content-Type': isFormData ? 'multipart/form-data' : 'application/json'}}
            );
            setData(response.data)
        } catch (error) {
            setError(error)
        } finally {
            setLoading(false);
        }
    }

    const putDataPromise = async (endpoint: string, body: any) => {
        return await customAxios.put<T>(endpoint, body);
    }

    useEffect(() => {
        if (fireAutomatically) {
            putData(endpoint, body);
        }
    }, [fireAutomatically, isPrivate, endpoint]);

    return {data, error, isLoading, putData, putDataPromise};
}

export default useDataPut;