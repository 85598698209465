import {useEffect, useState} from "react";
import {Outlet} from "react-router-dom";
import useDataPost from "../../hooks/useDataPost";
import useAuth from "../../hooks/useAuth";
import {SocietyDTO} from "../../context/SocietyProvider";
import useSociety from "../../hooks/useSociety";
import SocietyNotFound from "../societyNotFound/SocietyNotFound";
import {useSectionsRefs} from "../mainWrapper/MainWrapper";
import useCustomTheme from "../../hooks/useCustomTheme";

interface SocietyWrapperProps {
    subdomain: string
}

const SocietyWrapper = ({subdomain}: SocietyWrapperProps) => {
    const {setThemeName} = useCustomTheme();

    const [isProcessing, setIsProcessing] = useState(true);
    const [error, setError] = useState<any>(null);
    const {society, setSociety} = useSociety();
    const {auth} = useAuth();
    const isTokenPresent = !!auth.token;
    const {postDataPromise} = useDataPost<SocietyDTO>('', {}, isTokenPresent, false);

    const {
        articlesSection,
        societyDescriptionSection,
        contactSection,
        setNavItems,
        setDisplayRegisterSocietyButton,
        setDisplayCart,
        setRedirectToLootyHomepage,
        setDisplayLoginButton,
        setDisplayMobileMenu,
    } = useSectionsRefs();

    useEffect(() => {
        if (society && society.theme) {
            setThemeName(society.theme);
        }
    });

    useEffect(() => {
        let isMounted = true;

        const getSociety = async () => {
            postDataPromise('/societies/by-subdomain', {subdomain})
                .then(response => {
                    setSociety(response.data)
                }).catch(error => {
                setError(error)
            }).finally(() => {
                isMounted && setIsProcessing(false);
            })
        }

        Object.keys(society).length === 0 && !error && getSociety()

        return () => {
            isMounted = false;
        }

    }, [subdomain, error]);

    return (
        <>
            {isProcessing
                ? <p> Loading ... </p>
                : error ? <SocietyNotFound/> : <Outlet context={{
                    articlesSection,
                    societyDescriptionSection,
                    contactSection,
                    setNavItems,
                    setDisplayRegisterSocietyButton,
                    setDisplayCart,
                    setRedirectToLootyHomepage,
                    setDisplayLoginButton,
                    setDisplayMobileMenu,
                }}/>
            }
        </>
    )
}

export default SocietyWrapper;